<template>
    <div>
        <CoolLightBox 
            :items="items" 
            :fullScreen="true"
            :index="index"
            @close="index = null">
        </CoolLightBox>
        <div id="home" class="saas-banner">
            <div class="effect"></div>
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="saas-banner-content">
                            <h1>Ultimate Platform to monitor your best workflow.</h1>
                            <p>It should be noted that although application software is thought of as a program, it can be anything that runs on a computer.</p>
                            <div class="btn-box">
                                <a href="#" class="btn btn-primary">Get Started</a>
                                <div 
                                    class="popup-youtube video-btn"
                                    v-for="(image, imageIndex) in items"
                                    :key="imageIndex"
                                    @click="index = imageIndex"
                                    style="cursor: pointer"
                                >
                                    <i class="fas fa-play"></i>
                                    Watch our video
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div id="particles-js"></div>

            <div class="saas-banner-image"><img src="../../assets/images/laptop.png" alt="image"></div>
        </div>
    </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

    export default {
        name: 'MainBanner',
        components: { CoolLightBox },
        data: function () {
            return {
                items: [
                    {
                        src: 'https://www.youtube.com/watch?v=bk7McNUjWgw',
                    }
                ],
                index: null
            };
        },
    }
</script>