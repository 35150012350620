<template>
    <div class="blog-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-8 col-md-12">
                    <div class="row">
                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-item">
                                <div class="blog-image">
                                    <a href="#"><img src="../../assets/images/blog1.jpg" alt="image"></a>

                                    <div class="post-tag">
                                        <a href="#">Technology</a>
                                    </div>
                                </div>

                                <div class="blog-post-content">
                                    <span class="date">25 Feb, 2019</span>
                                    <h3><a href="#">The Most Popular New top Business Apps</a></h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                                    <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-item">
                                <div class="blog-image">
                                    <a href="#"><img src="../../assets/images/blog2.jpg" alt="image"></a>

                                    <div class="post-tag">
                                        <a href="#">Agency</a>
                                    </div>
                                </div>

                                <div class="blog-post-content">
                                    <span class="date">27 Feb, 2019</span>
                                    <h3><a href="#">The Best Marketing top use Management Tools</a></h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                                    <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-item">
                                <div class="blog-image">
                                    <a href="#"><img src="../../assets/images/blog3.jpg" alt="image"></a>

                                    <div class="post-tag">
                                        <a href="#">IT</a>
                                    </div>
                                </div>

                                <div class="blog-post-content">
                                    <span class="date">28 Feb, 2019</span>
                                    <h3><a href="#">3 WooCommerce Plugins to Boost Sales</a></h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                                    <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-item">
                                <div class="blog-image">
                                    <a href="#"><img src="../../assets/images/blog4.jpg" alt="image"></a>

                                    <div class="post-tag">
                                        <a href="#">Creative</a>
                                    </div>
                                </div>

                                <div class="blog-post-content">
                                    <span class="date">29 Feb, 2019</span>
                                    <h3><a href="#">Top 21 Must-Read Blogs For Creative Agencies</a></h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                                    <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-item">
                                <div class="blog-image">
                                    <a href="#"><img src="../../assets/images/blog5.jpg" alt="image"></a>

                                    <div class="post-tag">
                                        <a href="#">Technology</a>
                                    </div>
                                </div>

                                <div class="blog-post-content">
                                    <span class="date">25 Feb, 2019</span>
                                    <h3><a href="#">The Most Popular New top Business Apps</a></h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                                    <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-6">
                            <div class="single-blog-item">
                                <div class="blog-image">
                                    <a href="#"><img src="../../assets/images/blog6.jpg" alt="image"></a>

                                    <div class="post-tag">
                                        <a href="#">Agency</a>
                                    </div>
                                </div>

                                <div class="blog-post-content">
                                    <span class="date">27 Feb, 2019</span>
                                    <h3><a href="#">The Best Marketing top use Management Tools</a></h3>
                                    <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                                    <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12 col-md-12">
                            <div class="pagination-area">
                                <nav aria-label="Page navigation">
                                    <ul class="pagination justify-content-center">
                                        <li class="page-item"><a class="page-link" href="#"><i class="fas fa-angle-double-left"></i></a></li>
                                        <li class="page-item"><a class="page-link active" href="#">1</a></li>
                                        <li class="page-item"><a class="page-link" href="#">2</a></li>
                                        <li class="page-item"><a class="page-link" href="#">3</a></li>
                                        <li class="page-item"><a class="page-link" href="#"><i class="fas fa-angle-double-right"></i></a></li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="sidebar">
                        <div class="widget widget_search">
                            <form>
                                <input type="text" class="form-control" placeholder="Search here...">
                                <button type="submit"><i class="icofont-search"></i></button>
                            </form>
                        </div>
                        
                        <div class="widget widget_categories">
                            <h3 class="widget-title">
                                Categories
                            </h3>

                            <ul>
                                <li><a href="#">AJAX</a></li>
                                <li><a href="#">Apache</a></li>
                                <li><a href="#">CSS</a></li>
                                <li><a href="#">PHP</a></li>
                                <li><a href="#">Django</a></li>
                                <li><a href="#">Error</a></li>
                                <li><a href="#">IIS</a></li>
                                <li><a href="#">JavaScript</a></li>
                            </ul>
                        </div>
                        
                        <div class="widget widget_recent_entries">
                            <h3 class="widget-title">
                                Recent Posts
                            </h3>

                            <ul>
                                <li>
                                    <a href="#">
                                        <img src="../../assets/images/blog1.jpg" alt="image">
                                    </a>

                                    <h5><a href="#">The Most Popular New top Business Apps</a></h5>
                                    <p class="date">21 March, 2019</p>
                                </li>

                                <li>
                                    <a href="#">
                                        <img src="../../assets/images/blog2.jpg" alt="image">
                                    </a>

                                    <h5><a href="#">3 WooCommerce Plugins to Boost Sales</a></h5>
                                    <p class="date">20 March, 2019</p>
                                </li>

                                <li>
                                    <a href="#">
                                        <img src="../../assets/images/blog3.jpg" alt="image">
                                    </a>

                                    <h5><a href="#">The Best Marketing top use Management Tools</a></h5>
                                    <p class="date">27 March, 2019</p>
                                </li>

                                <li>
                                    <a href="#">
                                        <img src="../../assets/images/blog4.jpg" alt="image">
                                    </a>

                                    <h5><a href="#">Top 21 Must-Read Blogs For Creative Agencies</a></h5>
                                    <p class="date">27 January, 2019</p>
                                </li>
                            </ul>
                        </div>
                        
                        <div class="widget widget_tag_cloud">
                            <h3 class="widget-title">
                                Tags
                            </h3>

                            <div class="tagcloud">
                                <a href="#">Error</a>
                                <a href="#">Cake Bake</a>
                                <a href="#">Dromzone</a>
                                <a href="#">File</a>
                                <a href="#">Yii</a>
                                <a href="#">Yii2</a>
                                <a href="#">UUID</a>
                                <a href="#">Setup</a>
                                <a href="#">Error</a>
                                <a href="#">Cake Bake</a>
                                <a href="#">Dromzone</a>
                                <a href="#">File</a>
                                <a href="#">Yii</a>
                                <a href="#">Yii2</a>
                                <a href="#">UUID</a>
                                <a href="#">Setup</a>
                            </div>
                        </div>
                        
                        <div class="widget widget_archive">
                            <h3 class="widget-title">
                                Archives
                            </h3>

                            <ul>
                                <li><a href="#">December 2018</a></li>
                                <li><a href="#">January 2019</a></li>
                                <li><a href="#">February 2019</a></li>
                                <li><a href="#">March 2019</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Blog'
    }
</script>