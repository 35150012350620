<template>
    <div class="services-area ptb-100 pt-0">
        <div class="container">
            <div class="services-slides owl-carousel owl-theme">
                <carousel
                    :item = "1"
                    :autplay = "true"
                    :loop = "true"
                    :autoplaySpeed = "true"
                    :autoplayTimeout = "500"
                    :paginationEnabled = "true"
                    :perPageCustom = "[[0, 1], [768, 2], [1200, 3]]"
                >
                    <slide>
                        <div class="single-services">
                            <i class="fas fa-laptop"></i>
                            <h3>Web Design</h3>
                            <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        </div>
                    </slide>

                    <slide>
                        <div class="single-services">
                            <i class="fas fa-mobile-alt"></i>
                            <h3>Mobile Development</h3>
                            <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        </div>
                    </slide>

                    <slide>
                        <div class="single-services">
                            <i class="fas fa-rocket"></i>
                            <h3>Branding Identity</h3>
                            <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        </div>
                    </slide>

                    <slide>
                        <div class="single-services">
                                <i class="fas fa-camera"></i>
                                <h3>Photography</h3>
                            <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        </div>
                    </slide>

                    <slide>
                        <div class="single-services">
                                <i class="fab fa-sketch"></i>
                                <h3>Sketch</h3>
                            <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        </div>
                    </slide>

                    <slide>
                        <div class="single-services">
                            <i class="fas fa-laptop"></i>
                            <h3>Web Design</h3>
                            <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

    export default {
        name: 'ServicesSlider',
        components: {
            Carousel,
            Slide
        }
    }
</script>