import VueRouter from 'vue-router'

// All Pages
import Agency from './components/Pages/Agency'
import Freelancer from './components/Pages/Freelancer'
import Saas from './components/Pages/Saas'
import Software from './components/Pages/Software'
import App from './components/Pages/App'
import Generation from './components/Pages/Generation'
import Subscription from './components/Pages/Subscription'
import Payment from './components/Pages/Payment'
import BlogOne from './components/Pages/BlogOne'
import BlogTwo from './components/Pages/BlogTwo'
import BlogDetails from './components/Pages/BlogDetails'
import Login from './components/Pages/Login'
import Signup from './components/Pages/Signup'
import Error from './components/Pages/Error'

export const router = new VueRouter({
    mode: 'history',
    linkExactActiveClass: 'active',
    scrollBehavior(){
        return { x: 0, y: 0 }
    },

    routes: [
        {path: '/', component: Agency},
        {path: '/freelancer', component: Freelancer},
        {path: '/saas', component: Saas},
        {path: '/software', component: Software},
        {path: '/app', component: App},
        {path: '/generation', component: Generation},
        {path: '/subscription', component: Subscription},
        {path: '/payment', component: Payment},
        {path: '/blog-one', component: BlogOne},
        {path: '/blog-two', component: BlogTwo},
        {path: '/blog-details', component: BlogDetails},
        {path: '/login', component: Login},
        {path: '/signup', component: Signup},
        {path: '/error', component: Error},
    ]
})