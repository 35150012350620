<template>
    <div>
        <Preloader v-if="isLoading" />
        <Navbar />
        <MainBanner />
        <ServicesSlider />
        <Features />
        <FeaturesUpdate />
        <FunFacts />
        <Feedback />
        <Pricing />
        <Faq />
        <FreeTrial />
        <Blog />
        <Partner />
        <Contact />
        <Subscribe />
        <Footer />
    </div>
</template>

<script>
    import Preloader from '../Saas/Preloader'
    import Navbar from '../Saas/Navbar'
    import MainBanner from '../Saas/MainBanner'
    import ServicesSlider from '../Saas/ServicesSlider'
    import Features from '../Saas/Features'
    import FeaturesUpdate from '../Saas/FeaturesUpdate'
    import FunFacts from '../Saas/FunFacts'
    import Feedback from '../Saas/Feedback'
    import Pricing from '../Saas/Pricing'
    import Faq from '../Saas/Faq'
    import FreeTrial from '../Saas/FreeTrial'
    import Blog from '../Saas/Blog'
    import Partner from '../Saas/Partner'
    import Contact from '../Saas/Contact'
    import Subscribe from '../Saas/Subscribe'
    import Footer from '../Saas/Footer'

    export default {
        name: 'Saas',
        components: {
            Preloader,
            Navbar,
            MainBanner,
            ServicesSlider,
            Features,
            FeaturesUpdate,
            FunFacts,
            Feedback,
            Pricing,
            Faq,
            FreeTrial,
            Blog,
            Partner,
            Contact,
            Subscribe,
            Footer,
        },
        data() {
            return {
                isLoading: true
            }
        },
        mounted() {
            setTimeout(() => {
                this.isLoading = false
            }, 2000)
        }
    }
</script>