<template>
    <nav :class="['navbar navbar-expand-lg navbar-style-one navbar-light bg-light', {'is-sticky': isSticky}]">
        <div class="container">
            <router-link class="navbar-brand" to="/blog-one">
                <img src="../../assets/images/white-logo.png" alt="logo">
                <img src="../../assets/images/black-logo.png" alt="logo">
            </router-link>

            <b-navbar-toggle target="navbarSupportedContent">
                <span class="icon-bar top-bar"></span>
                <span class="icon-bar middle-bar"></span>
                <span class="icon-bar bottom-bar"></span>
            </b-navbar-toggle>

            <b-collapse class="collapse navbar-collapse" id="navbarSupportedContent" is-nav>
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <router-link class="nav-link" to="/">Home</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/">About</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/">Features</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/">Testimonials</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/">Pricing</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/">News</router-link>
                    </li>
                    <li class="nav-item">
                        <router-link class="nav-link" to="/">Contact</router-link>
                    </li>
                </ul>

                <ul class="others-option">
                    <li><a href="#" class="btn btn-primary">sign in</a></li>
                </ul>
            </b-collapse>
        </div>
    </nav>
</template>

<script>
    export default {
        name: 'Navbar',
        data(){
            return {
                isSticky: false
            }
        },

        mounted(){
            const that = this
            window.addEventListener('scroll', () => {
                let scrollPos = window.scrollY
                if(scrollPos >= 100){
                    that.isSticky = true
                } else {
                    that.isSticky = false
                }
            })
        }
    }
</script>