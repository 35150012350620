<template>
    <div>
        <CoolLightBox 
            :items="items" 
            :fullScreen="true"
            :index="index"
            @close="index = null">
        </CoolLightBox>

        <div class="cta-area ptb-100 pb-0">
            <div class="container">
                <div class="cta-content">
                    <h2>We Always Try to Quality Work</h2>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <div
                        class="popup-youtube video-btn"
                        v-for="(image, imageIndex) in items"
                        :key="imageIndex"
                        @click="index = imageIndex"
                        style="cursor: pointer"
                        >
                        <i class="fas fa-play"></i>
                    </div>
                </div>

                <div class="cta-inner-content">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-cta-box">
                                <i class="fas fa-layer-group"></i>
                                <h3>Modern Design</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="single-cta-box">
                                <i class="fas fa-fighter-jet"></i>
                                <h3>Fast Loading</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div class="single-cta-box">
                                <i class="fas fa-broom"></i>
                                <h3>Clean Code</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
    name: 'Work',
    components: { CoolLightBox },
    data: function () {
        return {
            items: [
                {
                    src: 'https://www.youtube.com/watch?v=bk7McNUjWgw',
                }
            ],
            index: null
        };
    },
}
</script>