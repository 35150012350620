<template>
    <div id="home" class="car-subscription-banner">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6">
                            <div class="car-subscription-banner-content">
                                <h1>Own the moment, not the car!</h1>
                                <p>It should be noted that although application software is thought of as a program, it can be anything that runs on a computer.</p>

                                <form>
                                    <input type="text" class="form-control" placeholder="Enter email adrees">
                                    <button type="submit" class="btn btn-primary">Subscribe Now!</button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <div class="car-subscription-banner-image">
            <img src="../../assets/images/car-with-man.png" alt="image">
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MainBanner'
    }
</script>