<template>
    <div>
        <Preloader v-if="isLoading" />
        <Navbar />
        <MainBanner />
        <Boxes />
        <About />
        <ServicesSlider />
        <Skill />
        <Portfolio />
        <FunFacts />
        <Feedback />
        <Interests />
        <HireMe />
        <Blog />
        <Contact />
        <Footer />
    </div>
</template>

<script>
    import Preloader from '../Agency/Preloader'
    import Navbar from '../Freelancer/Navbar'
    import MainBanner from '../Freelancer/MainBanner'
    import  Boxes from '../Freelancer/Boxes'
    import About from '../Freelancer/About'
    import ServicesSlider from '../Freelancer/ServicesSlider'
    import Skill from '../Freelancer/Skill'
    import Portfolio from '../Freelancer/Portfolio'
    import FunFacts from '../Freelancer/FunFacts'
    import Feedback from '../Freelancer/Feedback'
    import Interests from '../Freelancer/Interests'
    import HireMe from '../Freelancer/HireMe'
    import Blog from '../Freelancer/Blog'
    import Contact from '../Freelancer/Contact'
    import Footer from '../Freelancer/Footer'

    export default {
        name: 'Freelancer',
        components: {
            Preloader,
            Navbar,
            MainBanner,
            Boxes,
            About,
            ServicesSlider,
            Skill,
            Portfolio,
            FunFacts,
            Feedback,
            Interests,
            HireMe,
            Blog,
            Contact,
            Footer,
        },
        data() {
            return {
                isLoading: true
            }
        },
        mounted() {
            setTimeout(() => {
                this.isLoading = false
            }, 2000)
        }
    }
</script>