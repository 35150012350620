<template>
    <div>
        <div id="contact" class="car-subscription-contact-area bg-f5fbff">
            <div class="container">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="contact-box">
                            <h3>Ready to go?</h3>
                            <a href="#" class="btn btn-primary">Browse Cars</a>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-6">
                        <div class="contact-box">
                            <h3>Have question?</h3>
                            <ul>
                                <li><a href="#">Read our FAQ</a></li>
                                <li><a href="#">support@temva.com</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <back-to-top bottom="50px" right="50px">
            <div class="go-top">
                <i class="fas fa-angle-up"></i>
            </div>
        </back-to-top>
    </div>
</template>

<script>
    export default {
        name: 'FooterTop'
    }
</script>