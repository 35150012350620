<template>
    <div id="how-it-works" class="how-it-works ptb-100 bg-f5fbff">
        <div class="container">
            <div class="car-subscription-section-title">
                <span>About</span>
                <h2>How it Works</h2>
                <img src="../../assets/images/car-icon.png" alt="image">
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-work-process">
                        <span>.01</span>
                        <h3>Select Your Car</h3>
                        <p>This should be used to tell a story and talk about your product.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-work-process">
                        <span>.02</span>
                        <h3>Confirm The Pickup</h3>
                        <p>This should be used to tell a story and talk about your product.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                    <div class="single-work-process">
                        <span>.03</span>
                        <h3>Drive</h3>
                        <p>This should be used to tell a story and talk about your product.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Works'
    }
</script>