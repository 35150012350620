<template>
    <div id="testimonials" class="feedback-area ptb-100">
        <div class="container">
            <div class="lead-generation-section-title">
                <span>Testimonials</span>
                <h2>What Our Customers Are Saying About Us</h2>
                <div class="bar"></div>
                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            </div>

            <div class="feedback-slides owl-carousel owl-theme">
                <carousel
                    :autplay = "true"
                    :loop = "true"
                    :paginationEnabled = "false"
                    :autoplaySpeed = "true"
                    :autoplayTimeout = "500"
                    :perPageCustom = "[[0, 1], [768, 2], [1200, 3]]"
                >
                    <slide>
                        <div class="feedback-item">
                            <img src="../../assets/images/client1.png" alt="client">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div class="client-info">
                                <h3>Jason Sthatham</h3>
                                <span>CEO ThemeForest</span>
                            </div>

                            <i class="fas fa-quote-left"></i>
                        </div>
                    </slide>

                    <slide>
                        <div class="feedback-item">
                            <img src="../../assets/images/client2.png" alt="client">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div class="client-info">
                                <h3>Steven Smith</h3>
                                <span>CEO EnvyTheme</span>
                            </div>

                            <i class="fas fa-quote-left"></i>
                        </div>
                    </slide>

                    <slide>
                        <div class="feedback-item">
                            <img src="../../assets/images/client3.png" alt="client">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div class="client-info">
                                <h3>David Warner</h3>
                                <span>CEO Company</span>
                            </div>

                            <i class="fas fa-quote-left"></i>
                        </div>
                    </slide>

                    <slide>
                        <div class="feedback-item">
                            <img src="../../assets/images/client1.png" alt="client">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div class="client-info">
                                <h3>Jason Sthatham</h3>
                                <span>CEO ThemeForest</span>
                            </div>

                            <i class="fas fa-quote-left"></i>
                        </div>
                    </slide>

                    <slide>
                        <div class="feedback-item">
                            <img src="../../assets/images/client2.png" alt="client">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div class="client-info">
                                <h3>Steven Smith</h3>
                                <span>CEO EnvyTheme</span>
                            </div>

                            <i class="fas fa-quote-left"></i>
                        </div>
                    </slide>

                    <slide>
                        <div class="feedback-item">
                            <img src="../../assets/images/client3.png" alt="client">
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                            <div class="client-info">
                                <h3>David Warner</h3>
                                <span>CEO Company</span>
                            </div>

                            <i class="fas fa-quote-left"></i>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
    import { Carousel, Slide } from 'vue-carousel'

    export default {
        name: 'Feedback',
        components: {
            Carousel,
            Slide
        },
    }
</script>