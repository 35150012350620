<template>
    <div id="features" class="how-its-work ptb-100">
        <div class="container">
            <div class="app-section-title">
                <h2>Amazing <span>Features</span></h2>
                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            </div>

            <div class="features-tab how-its-work-tab">
                <b-card no-body>
                    <b-tabs pills card vertical>
                        <b-tab title="one click demo import">
                            <img src="../../assets/images/laptop-with-dashoard.png" alt="image">
                        </b-tab>
                        
                        <b-tab title="drop & drag editing">
                            <img src="../../assets/images/laptop-with-dashoard.png" alt="image">
                        </b-tab>

                        <b-tab title="in-line editing">
                            <img src="../../assets/images/laptop-with-dashoard.png" alt="image">
                        </b-tab>

                        <b-tab title="budget overview">
                            <img src="../../assets/images/laptop-with-dashoard.png" alt="image">
                        </b-tab>

                        <b-tab title="create & adjust">
                            <img src="../../assets/images/laptop-with-dashoard.png" alt="image">
                        </b-tab>

                        <b-tab title="view reports">
                            <img src="../../assets/images/laptop-with-dashoard.png" alt="image">
                        </b-tab>

                        <b-tab title="create & adjust">
                            <img src="../../assets/images/laptop-with-dashoard.png" alt="image">
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Features'
    }
</script>