<template>
    <div>
        <Preloader v-if="isLoading" />
        <Navbar />
        <MainBanner />
        <Partner />
        <About />
        <Features />
        <Research />
        <Work />
        <Funfacts />
        <Feedback />
        <Pricing />
        <Blog />
        <Contact />
        <Subscribe />
        <Footer />
    </div>
</template>

<script>
    import Preloader from '../Agency/Preloader'
    import Navbar from '../Agency/Navbar'
    import MainBanner from '../Agency/MainBanner'
    import Partner from '../Agency/Partner'
    import About from '../Agency/About'
    import Features from '../Agency/Features'
    import Research from '../Agency/Research'
    import Work from '../Agency/Work'
    import Funfacts from '../Agency/Funfacts'
    import Feedback from '../Agency/Feedback'
    import Pricing from '../Agency/Pricing'
    import Blog from '../Agency/Blog'
    import Contact from '../Agency/Contact'
    import Subscribe from '../Agency/Subscribe'
    import Footer from '../Agency/Footer'

    export default {
        name: 'Agency',
        components: {
            Preloader,
            Navbar,
            MainBanner,
            Partner,
            About,
            Features,
            Research,
            Work,
            Funfacts,
            Feedback,
            Pricing,
            Blog,
            Contact,
            Subscribe,
            Footer,
        },
        data() {
            return {
                isLoading: true
            }
        },
        mounted() {
            setTimeout(() => {
                this.isLoading = false
            }, 2000)
        }
    }
</script>