<template>
    <div>
        <Preloader v-if="isLoading" />
        <ErrorArea />
    </div>
</template>

<script>
    import Preloader from '../Error/Preloader'
    import ErrorArea from '../Error/ErrorArea'

    export default {
        name: 'Error',
        components: {
            Preloader, 
            ErrorArea,
        },
        data() {
            return {
                isLoading: true
            }
        },
        mounted() {
            setTimeout(() => {
                this.isLoading = false
            }, 2000)
        }
    }
</script>