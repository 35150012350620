<template>
<div>
    <CoolLightBox 
        :items="items" 
        :fullScreen="true"
        :index="index"
        @close="index = null">
    </CoolLightBox>

    <div id="home" class="software-banner">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="software-banner-content">
                                <h1>Ultimate Platform to Monitor Your <span>Best Workflow.</span></h1>
                                <p>It should be noted that although application software is thought of as a program, it can be anything that runs on a computer.</p>
                                
                                <div class="btn-box">
                                    <a href="#" class="btn btn-primary">try it free</a>
                                    <div 
                                        class="popup-youtube video-btn"
                                        v-for="(image, imageIndex) in items"
                                        :key="imageIndex"
                                        @click="index = imageIndex"
                                        style="cursor: pointer"
                                    >
                                        <i class="fab fa-google-play"></i> 
                                        Watch Our Video
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="software-banner-image">
                                <img src="../../assets/images/software-banner.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    </div>
</template>

<script>
    import CoolLightBox from 'vue-cool-lightbox'
    import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

    export default {
        name: 'MainBanner',
        components: { CoolLightBox },
        data: function () {
            return {
                items: [
                    {
                        src: 'https://www.youtube.com/watch?v=bk7McNUjWgw',
                    }
                ],
                index: null
            };
        },
    }
</script>