<template>
    <div id="about" class="how-its-work ptb-100 pb-0">
        <div class="container">
            <div class="software-section-title">
                <h2>How Does It's Work</h2>
                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                <img src="../../assets/images/line.png" alt="line">
            </div>

            <div class="features-tab how-its-work-tab">
                <b-card no-body>
                    <b-tabs pills card vertical>
                        <b-tab title="one click demo import">
                            <img src="../../assets/images/laptop-with-dashoard.png" alt="image">
                        </b-tab>
                        
                        <b-tab title="drop & drag editing">
                            <img src="../../assets/images/laptop-with-dashoard.png" alt="image">
                        </b-tab>

                        <b-tab title="in-line editing">
                            <img src="../../assets/images/laptop-with-dashoard.png" alt="image">
                        </b-tab>

                        <b-tab title="budget overview">
                            <img src="../../assets/images/laptop-with-dashoard.png" alt="image">
                        </b-tab>

                        <b-tab title="create & adjust">
                            <img src="../../assets/images/laptop-with-dashoard.png" alt="image">
                        </b-tab>

                        <b-tab title="view reports">
                            <img src="../../assets/images/laptop-with-dashoard.png" alt="image">
                        </b-tab>

                        <b-tab title="create & adjust">
                            <img src="../../assets/images/laptop-with-dashoard.png" alt="image">
                        </b-tab>
                    </b-tabs>
                </b-card>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HowItsWork'
    }
</script>