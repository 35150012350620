<template>
    <div>
        <Preloader v-if="isLoading" />
        <Navbar />
        <MainBanner />
        <Partner />
        <About />
        <Works />
        <Features />
        <Testimonial />
        <Download />
        <Faq />
        <Blog />
        <Subscribe />
        <Footer />
    </div>
</template>

<script>
    import Preloader from '../App/Preloader'
    import Navbar from '../App/Navbar'
    import MainBanner from '../App/MainBanner'
    import Partner from '../App/Partner'
    import About from '../App/About'
    import Works from '../App/Works'
    import  Features from '../App/Features'
    import Testimonial from '../App/Testimonial'
    import Download from '../App/Download'
    import Faq from '../App/Faq'
    import Blog from '../App/Blog'
    import Subscribe from '../App/Subscribe'
    import Footer from '../App/Footer'

    export default {
        name: 'App',
        components: {
            Preloader,
            Navbar,
            MainBanner,
            Partner,
            About,
            Works,
            Features,
            Testimonial,
            Download,
            Faq,
            Blog,
            Subscribe,
            Footer,
        },
        data() {
            return {
                isLoading: true
            }
        },
        mounted() {
            setTimeout(() => {
                this.isLoading = false
            }, 2000)
        }
    }
</script>