<template>
    <div class="blog-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-item">
                        <div class="blog-image">
                            <a href="#"><img src="../../assets/images/blog1.jpg" alt="image"></a>

                            <div class="post-tag">
                                <a href="#">Technology</a>
                            </div>
                        </div>

                        <div class="blog-post-content">
                            <span class="date">25 Feb, 2019</span>
                            <h3><a href="#">The Most Popular New top Business Apps</a></h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                            <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-item">
                        <div class="blog-image">
                            <a href="#"><img src="../../assets/images/blog2.jpg" alt="image"></a>

                            <div class="post-tag">
                                <a href="#">Agency</a>
                            </div>
                        </div>

                        <div class="blog-post-content">
                            <span class="date">27 Feb, 2019</span>
                            <h3><a href="#">The Best Marketing top use Management Tools</a></h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                            <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-item">
                        <div class="blog-image">
                            <a href="#"><img src="../../assets/images/blog3.jpg" alt="image"></a>

                            <div class="post-tag">
                                <a href="#">IT</a>
                            </div>
                        </div>

                        <div class="blog-post-content">
                            <span class="date">28 Feb, 2019</span>
                            <h3><a href="#">3 WooCommerce Plugins to Boost Sales</a></h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                            <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-item">
                        <div class="blog-image">
                            <a href="#"><img src="../../assets/images/blog4.jpg" alt="image"></a>

                            <div class="post-tag">
                                <a href="#">Creative</a>
                            </div>
                        </div>

                        <div class="blog-post-content">
                            <span class="date">29 Feb, 2019</span>
                            <h3><a href="#">Top 21 Must-Read Blogs For Creative Agencies</a></h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                            <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-item">
                        <div class="blog-image">
                            <a href="#"><img src="../../assets/images/blog5.jpg" alt="image"></a>

                            <div class="post-tag">
                                <a href="#">Technology</a>
                            </div>
                        </div>

                        <div class="blog-post-content">
                            <span class="date">25 Feb, 2019</span>
                            <h3><a href="#">The Most Popular New top Business Apps</a></h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                            <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-item">
                        <div class="blog-image">
                            <a href="#"><img src="../../assets/images/blog6.jpg" alt="image"></a>

                            <div class="post-tag">
                                <a href="#">Agency</a>
                            </div>
                        </div>

                        <div class="blog-post-content">
                            <span class="date">27 Feb, 2019</span>
                            <h3><a href="#">The Best Marketing top use Management Tools</a></h3>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                            <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-12">
                    <div class="pagination-area">
                        <nav aria-label="Page navigation">
                            <ul class="pagination justify-content-center">
                                <li class="page-item"><a class="page-link" href="#"><i class="fas fa-angle-double-left"></i></a></li>
                                <li class="page-item"><a class="page-link active" href="#">1</a></li>
                                <li class="page-item"><a class="page-link" href="#">2</a></li>
                                <li class="page-item"><a class="page-link" href="#">3</a></li>
                                <li class="page-item"><a class="page-link" href="#"><i class="fas fa-angle-double-right"></i></a></li>
                            </ul>
                        </nav>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Blog'
    }
</script>