<template>
    <div class="funfacts-area ptb-100 bg-f9f9f9">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-funfact">
                        <h3>1078</h3>
                        <p>Completed Projects</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-funfact">
                        <h3>250048</h3>
                        <p>Lines Code</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-funfact">
                        <h3>250</h3>
                        <p>Team Members</p>
                    </div>
                </div>

                <div class="col-lg-3 col-sm-6 col-md-3">
                    <div class="single-funfact">
                        <h3>2045</h3>
                        <p>Happy Clients</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Funfacts'
    }
</script>