<template>
    <div>
        <CoolLightBox 
            :items="items" 
            :fullScreen="true"
            :index="index"
            @close="index = null">
        </CoolLightBox>

        <div id="home" class="freelancer-banner">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-6 col-md-8">
                                <div class="freelancer-banner-content">
                                    <h1>I'm A Full Stack Web <span>Developer</span></h1>
                                    <ul>
                                        <li><a href="#" target="_blank"><i class="fab fa-github"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="fab fa-codepen"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="fab fa-stack-overflow"></i></a></li>
                                        <li><a href="#" target="_blank"><i class="fab fa-gitlab"></i></a></li>
                                    </ul>
                                    <div class="btn-box">
                                        <a href="#" class="btn btn-primary">Get Started</a>
                                        <div
                                            class="popup-youtube video-btn"
                                            v-for="(image, imageIndex) in items"
                                            :key="imageIndex"
                                            @click="index = imageIndex"
                                            style="cursor: pointer"
                                        >
                                            <i class="fas fa-play"></i>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-4">
                                <div class="freelancer-banner-image">
                                    <img src="../../assets/images/developer.png" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="back-text"><h1>Developer</h1></div>
        </div>
    </div>
</template>

<script>
    import CoolLightBox from 'vue-cool-lightbox'
    import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

    export default {
        name: 'MainBanner',
        components: { CoolLightBox },
        data: function () {
            return {
                items: [
                    {
                        src: 'https://www.youtube.com/watch?v=bk7McNUjWgw',
                    }
                ],
                index: null
            };
        },
    }
</script>