<template>
    <div class="featured-cars ptb-100 bg-f5fbff">
        <div class="container">
            <div class="car-subscription-section-title">
                <span>Featured Cars?</span>
                <h2>Featured Cars for Subscription</h2>
            </div>

            <div class="featured-cars-slides owl-carousel owl-theme">
                <carousel
                    :autplay = "true"
                    :loop = "true"
                    :paginationEnabled = "false"
                    :perPage="1"
                    :navigationEnabled = "true"
                    navigationNextLabel="<i class='fas fa-chevron-right'></i>"
                    navigationPrevLabel="<i class='fas fa-chevron-left'></i>"
                    :autoplaySpeed = "true"
                    :autoplayTimeout = "500"
                    :perPageCustom = "[[0, 1], [768, 2], [1200, 3]]"
                >
                    <slide>
                        <div class="single-featured-cars">
                            <img src="../../assets/images/featured-cars1.jpg" alt="cars">

                            <div class="featured-cars-content">
                                <h3><a href="#">2015 Toyota Corolla</a></h3>
                                <span>from $450/mo</span>
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="single-featured-cars">
                            <img src="../../assets/images/featured-cars2.jpg" alt="cars">

                            <div class="featured-cars-content">
                                <h3><a href="#">2015 Toyota Corolla</a></h3>
                                <span>from $450/mo</span>
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="single-featured-cars">
                            <img src="../../assets/images/featured-cars3.jpg" alt="cars">

                            <div class="featured-cars-content">
                                <h3><a href="#">2015 Toyota Corolla</a></h3>
                                <span>from $450/mo</span>
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="single-featured-cars">
                            <img src="../../assets/images/featured-cars2.jpg" alt="cars">

                            <div class="featured-cars-content">
                                <h3><a href="#">2015 Toyota Corolla</a></h3>
                                <span>from $450/mo</span>
                            </div>
                        </div>
                    </slide>
                    <slide>
                        <div class="single-featured-cars">
                            <img src="../../assets/images/featured-cars1.jpg" alt="cars">

                            <div class="featured-cars-content">
                                <h3><a href="#">2015 Toyota Corolla</a></h3>
                                <span>from $450/mo</span>
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="single-featured-cars">
                            <img src="../../assets/images/featured-cars2.jpg" alt="cars">

                            <div class="featured-cars-content">
                                <h3><a href="#">2015 Toyota Corolla</a></h3>
                                <span>from $450/mo</span>
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
    import { Carousel, Slide } from 'vue-carousel'

    export default {
        name: 'FeaturedCars',
        components: {
            Carousel,
            Slide
        },
    }
</script>