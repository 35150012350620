<template>
    <div id="contact" class="payment-processing-contact">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6">
                    <div class="contact-content-box">
                        <h3>Ready to get Started?</h3>
                        <p>No setup costs or contract - start taking payments today</p>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="contact-connect">
                        <a href="#" class="btn btn-primary">Sign Up Now</a>
                        <a href="mailto:temva@gmail.com" class="talk-to-sales">Talk to Sales</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Contact'
    }
</script>