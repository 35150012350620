<template>
    <div id="features" class="lead-generation-features ptb-100 bg-f5fbff">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="lead-generation-features-content">
                        <span>Our Features</span>
                        <h2>We Provide The Solutions To Grow Your Business</h2>
                        <p>This should be used to tell a story about your product or service. How can you benefit them?</p>

                        <div class="row">
                            <div class="col-lg-4 col-md-4">
                                <div class="box">
                                    <i class="fas fa-code"></i>
                                    <h3>Clean Code</h3>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4">
                                <div class="box">
                                    <i class="fab fa-android"></i>
                                    <h3>Android App</h3>
                                </div>
                            </div>

                            <div class="col-lg-4 col-md-4">
                                <div class="box">
                                    <i class="fas fa-pencil-ruler"></i>
                                    <h3>Design</h3>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="lead-generation-features-image">
                        <img src="../../assets/images/man-and-women.png" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Features'
    }
</script>