<template>
    <div class="cta-area-three ptb-100 bg-fffcf4">
        <div class="container">
            <div class="cta-content-three">
                <img src="../../assets/images/star.png" alt="image">
                <h3>General Data Protection Regulation (GDPR)</h3>
                <p>The reforms are designed to reflect the world we're living in now, and brings laws and obligations - including those around personal data, privacy and consent - across Europe up to speed for the internet-connected age.</p>
                <a href="#" class="btn btn-primary">Learn More</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'General'
    }
</script>