<template>
    <div id="about" class="about-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-about-box">
                        <div class="icon">
                            <i class="fas fa-camera"></i>
                        </div>
                        <h3>Camera Filter</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-about-box">
                        <div class="icon">
                            <i class="fas fa-fighter-jet"></i>
                        </div>
                        <h3>Fast and Optimized</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                    <div class="single-about-box">
                        <div class="icon">
                            <i class="far fa-hand-pointer"></i>
                        </div>
                        <h3>Drag and Drop</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
            </div>
        </div>

        <div class="about-inner-area">
            <div class="container-fluid">
                <div class="row h-100 justify-content-center align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="about-image">
                            <img src="../../assets/images/laptop.png" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="about-inner-content">
                            <div class="about-item">
                                <div class="icon">
                                    <i class="fas fa-phone"></i>
                                </div>
                                <h3>Free Caliing Service</h3>
                                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                            </div>

                            <div class="about-item">
                                <div class="icon">
                                    <i class="fas fa-gift"></i>
                                </div>
                                <h3>Daily Free Gift</h3>
                                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                            </div>

                            <div class="about-item">
                                <div class="icon">
                                    <i class="fas fa-qrcode"></i>
                                </div>
                                <h3>QR Code Scaner</h3>
                                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'About'
    }
</script>