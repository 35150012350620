<template>
    <div id="contact" class="contact-area ptb-100">
        <div class="container">
            <div class="saas-section-title">
                <h2>Get in Touch</h2>
                <div class="bar"></div>
                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            </div>

            <div class="contact-form">
                <div class="row align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="contact-info-box">
                            <p>Our 24/7 support team is here to help you and make sure our product is up to date.</p>

                            <span>Would love to hear from you. Call us directly at:
                                <a href="#">(224) 228-8475</a>
                            </span>
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <form id="contactForm">
                            <div class="row">
                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Name</label>
                                        <input type="text" class="form-control" required>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Email</label>
                                        <input type="email" class="form-control" required>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <div class="form-group">
                                        <label>Message</label>
                                        <textarea class="form-control" cols="30" rows="4" required></textarea>
                                    </div>
                                </div>

                                <div class="col-lg-12 col-md-12">
                                    <button type="submit" class="btn btn-primary">Send Message</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Contact'
    }
</script>