<template>
    <div id="features" class="features-area ptb-100 bg-f9f9f9">
        <div class="container">
            <div class="section-title">
                <h2>What's Different From Others</h2>
                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-features">
                        <div class="icon bg1">
                            <i class="fas fa-code"></i>
                        </div>
                        <h3>App Development</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-features">
                        <div class="icon bg2">
                            <i class="fas fa-cubes"></i>
                        </div>
                        <h3>Digital Branding</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-features">
                        <div class="icon bg3">
                            <i class="fas fa-cloud"></i>
                        </div>
                        <h3>Cloud Storage</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-features">
                        <div class="icon bg4">
                            <i class="fas fa-cogs"></i>
                        </div>
                        <h3>Customization</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-features">
                        <div class="icon bg5">
                            <i class="fas fa-pencil-ruler"></i>
                        </div>
                        <h3>UX Planning</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-features">
                        <div class="icon bg6">
                            <i class="fas fa-headset"></i>
                        </div>
                        <h3>Customer Support</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Features'
    }
</script> 