<template>
    <div id="portfolio" class="portfolio-area ptb-100 bg-f9f9f9">
        <div class="container">
            <div class="freelancer-section-title">
                <h2>My Latest <span>Work</span></h2>
                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            </div>

            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="shorting-menu">
                        <button class="filter" v-on:click="itemFilterKey = 'all'">All</button>
                        <button class="filter" v-on:click="itemFilterKey = 'design'">Design</button>
                        <button class="filter" v-on:click="itemFilterKey = 'seo'">SEO</button>
                        <button class="filter" v-on:click="itemFilterKey = 'branding'">Branding</button>
                        <button class="filter" v-on:click="itemFilterKey = 'print'">Print</button>
                        <button class="filter" v-on:click="itemFilterKey = 'video'">Video</button>
                    </div>
                </div>
            </div>

            <div class="shorting">
                <div class="row">
                    <div 
                        v-for="item in itemFilter"
                        :key="item.id"
                        class="col-lg-4 col-md-6"
                    >
                        <div class="single-work">
                            <div class="work-image">
                                <img :src="item.img" alt="work-image">

                                <div class="work-overlay">
                                    <h3>{{item.title}}</h3>
                                    <span>{{item.position}}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Portfolio',
        data: function (){
            return {
                itemFilterKey: 'all',
                items: [
                    {
                        id: 1,
                        img: require('../../assets/images/work-img1.jpg'),
                        title: 'Digital Marketing',
                        position: 'UI/UX',
                        key: 'design'
                    },
                    {
                        id: 2,
                        img: require('../../assets/images/work-img2.jpg'),
                        title: 'Digital Marketing',
                        position: 'UI/UX',
                        key: 'design'
                    },
                    {
                        id: 3,
                        img: require('../../assets/images/work-img3.jpg'),
                        title: 'Digital Marketing',
                        position: 'UI/UX',
                        key: 'design'
                    },
                    {
                        id: 4,
                        img: require('../../assets/images/work-img2.jpg'),
                        title: 'Digital Marketing',
                        position: 'UI/UX',
                        key: 'seo'
                    },
                    {
                        id: 5,
                        img: require('../../assets/images/work-img3.jpg'),
                        title: 'Digital Marketing',
                        position: 'UI/UX',
                        key: 'seo'
                    },
                    {
                        id: 6,
                        img: require('../../assets/images/work-img4.jpg'),
                        title: 'Digital Marketing',
                        position: 'UI/UX',
                        key: 'seo'
                    },
                    {
                        id: 7,
                        img: require('../../assets/images/work-img3.jpg'),
                        title: 'Digital Marketing',
                        position: 'UI/UX',
                        key: 'branding'
                    },
                    {
                        id: 8,
                        img: require('../../assets/images/work-img4.jpg'),
                        title: 'Digital Marketing',
                        position: 'UI/UX',
                        key: 'branding'
                    },
                    {
                        id: 9,
                        img: require('../../assets/images/work-img5.jpg'),
                        title: 'Digital Marketing',
                        position: 'UI/UX',
                        key: 'branding'
                    },
                    {
                        id: 10,
                        img: require('../../assets/images/work-img4.jpg'),
                        title: 'Digital Marketing',
                        position: 'UI/UX',
                        key: 'print'
                    },
                    {
                        id: 11,
                        img: require('../../assets/images/work-img5.jpg'),
                        title: 'Digital Marketing',
                        position: 'UI/UX',
                        key: 'video'
                    },
                    {
                        id: 12,
                        img: require('../../assets/images/work-img6.jpg'),
                        title: 'Digital Marketing',
                        position: 'UI/UX',
                        key: 'video'
                    },
                ]
            }
        },
        computed: {
            itemFilter(){
                return this[this.itemFilterKey]
            },
            all(){
                return this.items
            },
            design(){
                return this.items.filter((item) => item.key == 'design')
            },
            seo(){
                return this.items.filter((item) => item.key == 'seo')
            },
            branding(){
                return this.items.filter((item) => item.key == 'branding')
            },
            print(){
                return this.items.filter((item) => item.key == 'print')
            },
            video(){
                return this.items.filter((item) => item.key == 'video')
            }
        }
    }
</script>