<template>
    <div id="faq" class="faq-area ptb-100 bg-f9f9f9">
        <div class="container">
            <div class="section-title">
                <h2>Frequently Asked <span>Question</span></h2>
                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            </div>

            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="faq-accordion">
                        <ul class="accordion" role="tablist">
                            <b-card no-body class="accordion-item">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button class="accordion-title" block v-b-toggle.accordion-5 variant="info">How do permissions work in Google Play Instant? <i class="fas fa-plus"></i></b-button>
                                </b-card-header>
                                <b-collapse id="accordion-5" visible accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-card-text>{{ text }}</b-card-text>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>

                            <b-card no-body class="accordion-item">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button class="accordion-title" block v-b-toggle.accordion-6 variant="info">Is Smart Lock required for instant apps?<i class="fas fa-plus"></i></b-button>
                                </b-card-header>
                                <b-collapse id="accordion-6" visible accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-card-text>{{ text }}</b-card-text>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>

                            <b-card no-body class="accordion-item">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button class="accordion-title" block v-b-toggle.accordion-7 variant="info">Can I have multiple activities in a single feature?<i class="fas fa-plus"></i></b-button>
                                </b-card-header>
                                <b-collapse id="accordion-7" visible accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-card-text>{{ text }}</b-card-text>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>

                            <b-card no-body class="accordion-item">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button class="accordion-title" block v-b-toggle.accordion-8 variant="info">WCan I share resources between features?<i class="fas fa-plus"></i></b-button>
                                </b-card-header>
                                <b-collapse id="accordion-8" visible accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-card-text>{{ text }}</b-card-text>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                            
                            <b-card no-body class="accordion-item">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button class="accordion-title" block v-b-toggle.accordion-9 variant="info">Is multidex supported for instant apps?<i class="fas fa-plus"></i></b-button>
                                </b-card-header>
                                <b-collapse id="accordion-9" visible accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-card-text>{{ text }}</b-card-text>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>

                            <b-card no-body class="accordion-item">
                                <b-card-header header-tag="header" class="p-1" role="tab">
                                    <b-button class="accordion-title" block v-b-toggle.accordion-10 variant="info">Can I share resources between features?<i class="fas fa-plus"></i></b-button>
                                </b-card-header>
                                <b-collapse id="accordion-10" visible accordion="my-accordion" role="tabpanel">
                                    <b-card-body>
                                        <b-card-text>{{ text }}</b-card-text>
                                    </b-card-body>
                                </b-collapse>
                            </b-card>
                        </ul>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="faq-image">
                        <img src="../../assets/images/faq-bg.jpg" alt="img">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Faq',
        data() {
            return {
                text: `Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. incididunt ut labore et dolore magna aliqua.`
            }
        }
    }
</script>