<template>
    <div class="services-area ptb-100">
        <div class="container">
            <div class="saas-section-title">
                <h2>Our Featured Service that We Provide</h2>
                <div class="bar"></div>
                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            </div>
            <div class="services-slides owl-carousel owl-theme">
                <carousel
                    :item = "1"
                    :autplay = "true"
                    :loop = "true"
                    :autoplaySpeed = "true"
                    :autoplayTimeout = "500"
                    :paginationEnabled = "true"
                    :perPageCustom = "[[0, 1], [768, 2], [1200, 3]]"
                >
                    <slide>
                        <div class="single-services">
                            <div class="icon">
                                <i class="fas fa-solar-panel"></i>
                            </div>
                            <h3>Export Presets</h3>
                            <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        </div>
                    </slide>

                    <slide>
                        <div class="single-services">
                            <div class="icon bg-cdf1d8">
                                <i class="fas fa-mobile-alt"></i>
                            </div>
                            <h3>Mobile Friendly</h3>
                            <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        </div>
                    </slide>

                    <slide>
                        <div class="single-services">
                            <div class="icon bg-f78acb">
                                <i class="fas fa-th"></i>
                            </div>
                            <h3>Grid and Guides</h3>
                            <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        </div>
                    </slide>

                    <slide>
                        <div class="single-services">
                                <div class="icon">
                                    <i class="fas fa-code"></i>
                                </div>
                                <h3>Code Security</h3>
                            <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        </div>
                    </slide>

                    <slide>
                        <div class="single-services">
                                <div class="icon bg-c679e3">
                                    <i class="fab fa-creative-commons-zero"></i>
                                </div>
                                <h3>Zero Configuration</h3>
                            <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        </div>
                    </slide>

                    <slide>
                        <div class="single-services">
                            <div class="icon bg-eb6b3d">
                                <i class="fas fa-key"></i>
                            </div>
                            <h3>Access Controlled</h3>
                            <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
        <div class="services-inner-area">
            <div class="container-fluid">
                <div class="row h-100 justify-content-center align-items-center">
                    <div class="col-lg-6 col-md-12">
                        <div class="services-image">
                            <img src="../../assets/images/laptop.png" alt="image">
                        </div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="services-inner-content">
                            <div class="services-item">
                                <div class="icon">
                                    <i class="fas fa-phone"></i>
                                </div>
                                <h3>Free Caliing Service</h3>
                                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                            </div>

                            <div class="services-item">
                                <div class="icon">
                                    <i class="fas fa-gift"></i>
                                </div>
                                <h3>Daily Free Gift</h3>
                                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                            </div>

                            <div class="services-item">
                                <div class="icon">
                                    <i class="fas fa-qrcode"></i>
                                </div>
                                <h3>QR Code Scaner</h3>
                                <p>Plan ahead by day, week, or month, and see project status at a glance. Search and filter to focus in on anything form a single project to an individual person's workload.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

    export default {
        name: 'ServicesSlider',
        components: {
            Carousel,
            Slide
        }
    }
</script>