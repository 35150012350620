<template>
    <div id="pricing" class="pricing-area ptb-100 pt-0">
        <div class="container">
            <div class="saas-section-title">
                <h2>Our Pricing</h2>
                <div class="bar"></div>
                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="pricing-table">
                        <div class="pricing-header">
                            <div class="icon">
                                <img src="../../assets/images/icon1.png" alt="icon">
                            </div>

                            <h3>Basic Plan</h3>
                        </div>

                        <ul class="pricing-features">
                            <li class="active">5 GB Bandwidth</li>
                            <li class="active">Highest Speed</li>
                            <li class="active">1 GB Storage</li>
                            <li class="active">Unlimited Website</li>
                            <li class="active">Unlimited Users</li>
                            <li class="active">24x7 Great Support</li>
                            <li class="unactive">Data Security and Backups</li>
                            <li class="unactive">Monthly Reports and Analytics</li>
                        </ul>

                        <div class="price">
                            $549
                            <span>Per month</span>
                        </div>

                        <a href="#" class="btn btn-primary">Select</a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="pricing-table active">
                        <div class="pricing-header">
                            <div class="icon">
                                <img src="../../assets/images/icon2.png" alt="icon">
                            </div>

                            <h3>Advanced Plan</h3>
                        </div>

                        <ul class="pricing-features">
                            <li class="active">10 GB Bandwidth</li>
                            <li class="active">Highest Speed</li>
                            <li class="active">2 GB Storage</li>
                            <li class="active">Unlimited Website</li>
                            <li class="active">Unlimited Users</li>
                            <li class="active">24x7 Great Support</li>
                            <li class="active">Data Security and Backups</li>
                            <li class="unactive">Monthly Reports and Analytics</li>
                        </ul>

                        <div class="price">
                            $749
                            <span>Per month</span>
                        </div>

                        <a href="#" class="btn btn-primary">Select</a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                    <div class="pricing-table">
                        <div class="pricing-header">
                            <div class="icon">
                                <img src="../../assets/images/icon3.png" alt="icon">
                            </div>

                            <h3>Expert Plan</h3>
                        </div>

                        <ul class="pricing-features">
                            <li class="active">15 GB Bandwidth</li>
                            <li class="active">Highest Speed</li>
                            <li class="active">3 GB Storage</li>
                            <li class="active">Unlimited Website</li>
                            <li class="active">Unlimited Users</li>
                            <li class="active">24x7 Great Support</li>
                            <li class="active">Data Security and Backups</li>
                            <li class="active">Monthly Reports and Analytics</li>
                        </ul>

                        <div class="price">
                            $949
                            <span>Per month</span>
                        </div>

                        <a href="#" class="btn btn-primary">Select</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Pricing'
    }
</script>