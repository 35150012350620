<template>
    <div>
        <footer class="footer-area-three bg-fffcf4">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-7 col-md-7">
                        <p><i class="far fa-copyright"></i> 2022 Temva. All Rights Reserved</p>
                    </div>

                    <div class="col-lg-5 col-md-5">
                        <ul>
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>

        <back-to-top bottom="50px" right="50px">
            <div class="go-top">
                <i class="fas fa-angle-up"></i>
            </div>
        </back-to-top>
    </div>
</template>

<script>
    export default {
        name: 'Footer'
    }
</script>