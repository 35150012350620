<template>
    <div id="about" class="about-area ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="about-image">
                        <img src="../../assets/images/about-img1.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="about-content">
                        <h2>Make Your Website More Powerful</h2>
                        <p>We Design & Develop Websites that stand out of the crowd. Alea is an extremely feature rich, beautiful WordPress Template.</p>

                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="single-inner-content">
                                    <div class="title">
                                        <div class="icon">
                                            <i class="fas fa-desktop"></i>
                                        </div>

                                        <h3>Friendly Interface</h3>
                                    </div>

                                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="single-inner-content">
                                    <div class="title">
                                        <div class="icon">
                                            <i class="fas fa-tasks"></i>
                                        </div>

                                        <h3>Powerful Options</h3>
                                    </div>

                                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="single-inner-content">
                                    <div class="title">
                                        <div class="icon">
                                            <i class="fas fa-code"></i>
                                        </div>

                                        <h3>Organized Code</h3>
                                    </div>

                                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="single-inner-content">
                                    <div class="title">
                                        <div class="icon">
                                            <i class="fas fa-headset"></i>
                                        </div>

                                        <h3>Premium Support</h3>
                                    </div>

                                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>
                                </div>
                            </div>
                        </div>

                        <a href="#" class="btn btn-primary">Read More</a>
                    </div>
                </div>
            </div>
        </div>

        <div class="circle"><img src="../../assets/images/circle.png" alt="cricle"></div>
    </div>
</template>

<script>
    export default {
        name: 'About'
    }
</script>