<template>
    <div>
        <Preloader v-if="isLoading" />
        <Navbar />
        <MainBanner />
        <Partner />
        <Features />
        <StartNow />
        <SmartPayment />
        <HowItWorks />
        <FeaturesUpdate />
        <Platform />
        <Funfacts />
        <ShopOnline />
        <Contact />
        <Footer />
    </div>
</template>

<script>
    import Preloader from '../Payment/Preloader'
    import Navbar from '../Payment/Navbar'
    import MainBanner from '../Payment/MainBanner'
    import Partner from '../Payment/Partner'
    import Features from '../Payment/Features'
    import StartNow from '../Payment/StartNow'
    import SmartPayment from '../Payment/SmartPayment'
    import HowItWorks from '../Payment/HowItWorks'
    import FeaturesUpdate from '../Payment/FeaturesUpdate'
    import Platform from '../Payment/Platform'
    import Funfacts from  '../Payment/Funfacts'
    import ShopOnline from '../Payment/ShopOnline'
    import Contact from '../Payment/Contact'
    import Footer from '../Payment/Footer'

    export default {
        name: 'Payment',
        components: {
            Preloader,
            Navbar,
            MainBanner,
            Partner,
            Features,
            StartNow,
            SmartPayment,
            HowItWorks,
            FeaturesUpdate,
            Platform,
            Funfacts,
            ShopOnline,
            Contact,
            Footer,
        },
        data() {
            return {
                isLoading: true
            }
        },
        mounted() {
            setTimeout(() => {
                this.isLoading = false
            }, 2000)
        }
    }
</script>