<template>
    <div id="blog" class="blog-area ptb-100 bg-f9f9f9">
        <div class="container">
            <div class="freelancer-section-title">
                <h2>Latest <span>News</span></h2>
                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            </div>

            <div class="blog-slides owl-carousel owl-theme">
                <carousel
                    :autplay = "true"
                    :loop = "true"
                    :paginationEnabled = "true"
                    :autoplaySpeed = "true"
                    :autoplayTimeout = "500"
                    :perPageCustom = "[[0, 1], [768, 2], [1200, 3]]"
                >
                    <slide>
                        <div class="single-blog-item">
                            <div class="blog-image">
                                <a href="#"><img src="../../assets/images/blog1.jpg" alt="image"></a>

                                <div class="post-tag">
                                    <a href="#">Technology</a>
                                </div>
                            </div>

                            <div class="blog-post-content">
                                <span class="date">25 Feb, 2019</span>
                                <h3><a href="#">The Most Popular New top Business Apps</a></h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                                <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="single-blog-item">
                            <div class="blog-image">
                                <a href="#"><img src="../../assets/images/blog2.jpg" alt="image"></a>

                                <div class="post-tag">
                                    <a href="#">Agency</a>
                                </div>
                            </div>

                            <div class="blog-post-content">
                                <span class="date">27 Feb, 2019</span>
                                <h3><a href="#">The Best Marketing top use Management Tools</a></h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                                <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="single-blog-item">
                            <div class="blog-image">
                                <a href="#"><img src="../../assets/images/blog3.jpg" alt="image"></a>

                                <div class="post-tag">
                                    <a href="#">IT</a>
                                </div>
                            </div>

                            <div class="blog-post-content">
                                <span class="date">28 Feb, 2019</span>
                                <h3><a href="#">3 WooCommerce Plugins to Boost Sales</a></h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                                <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="single-blog-item">
                            <div class="blog-image">
                                <a href="#"><img src="../../assets/images/blog4.jpg" alt="image"></a>

                                <div class="post-tag">
                                    <a href="#">Creative</a>
                                </div>
                            </div>

                            <div class="blog-post-content">
                                <span class="date">29 Feb, 2019</span>
                                <h3><a href="#">Top 21 Must-Read Blogs For Creative Agencies</a></h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                                <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="single-blog-item">
                            <div class="blog-image">
                                <a href="#"><img src="../../assets/images/blog1.jpg" alt="image"></a>

                                <div class="post-tag">
                                    <a href="#">Technology</a>
                                </div>
                            </div>

                            <div class="blog-post-content">
                                <span class="date">25 Feb, 2019</span>
                                <h3><a href="#">The Most Popular New top Business Apps</a></h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                                <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="single-blog-item">
                            <div class="blog-image">
                                <a href="#"><img src="../../assets/images/blog2.jpg" alt="image"></a>

                                <div class="post-tag">
                                    <a href="#">Agency</a>
                                </div>
                            </div>

                            <div class="blog-post-content">
                                <span class="date">27 Feb, 2019</span>
                                <h3><a href="#">The Best Marketing top use Management Tools</a></h3>
                                <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum.</p>
                                <a href="#" class="read-more-btn">Read More <i class="icofont-double-right"></i></a>
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
    import { Carousel, Slide } from 'vue-carousel'

    export default {
        name: 'Blog',
        components: {
            Carousel,
            Slide
        },
    }
</script>