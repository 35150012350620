<template>
    <div class="page-title-area">
        <div class="container">
            <h2>Blog Details</h2>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'PageTitle'
    }
</script>