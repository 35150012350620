<template>
    <div>
        <Preloader v-if="isLoading" />
        <LoginArea />
    </div>
</template>

<script>
    import Preloader from '../Login/Preloader'
    import LoginArea from '../Login/LoginArea'

    export default {
        name: 'Login',
        components: {
            Preloader, 
            LoginArea,
        },
        data() {
            return {
                isLoading: true
            }
        },
        mounted() {
            setTimeout(() => {
                this.isLoading = false
            }, 2000)
        }
    }
</script>