<template>
    <div>
        <CoolLightBox 
            :items="items" 
            :fullScreen="true"
            :index="index"
            @close="index = null">
        </CoolLightBox>

        <div id="home" class="main-banner">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="main-banner-content">
                            <h1>Ultimate Platform to Monitor Your Best Workflow</h1>
                            <p>It should be noted that although application software is thought of as a program, it can be anything that runs on a computer.</p>
                            <div class="btn-box">
                                <a href="#" class="btn btn-primary">Get Started</a>
                                <div 
                                    class="popup-youtube video-btn"
                                    v-for="(image, imageIndex) in items"
                                    :key="imageIndex"
                                    @click="index = imageIndex"
                                    style="cursor: pointer"
                                >
                                    <i class="fab fa-google-play"> </i> 
                                    Watch Our Video
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

export default {
    name: 'MainBanner',
    components: { CoolLightBox },
    data: function () {
        return {
            items: [
                {
                    src: 'https://www.youtube.com/watch?v=bk7McNUjWgw',
                }
            ],
            index: null
        };
    },
}
</script>