<template>
    <div id="pricing" class="pricing-area ptb-100 bg-f5fbff">
        <div class="container">
            <div class="car-subscription-section-title">
                <span>Pricing</span>
                <h2>Pluck monthly Plans</h2>
                <img src="../../assets/images/car-icon.png" alt="car-icon">
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-pricing">
                        <div class="pricing-header">
                            <div class="icon">
                                <i class="fas fa-tachometer-alt"></i>
                            </div>

                            <h3>Economy</h3>
                        </div>

                        <ul class="pricing-features">
                            <li><i class="fas fa-check-circle"></i> insurance included</li>
                            <li><i class="fas fa-check-circle"></i> Toyota Yaris or Similar</li>
                            <li><i class="fas fa-check-circle"></i> insurance included</li>
                            <li><i class="fas fa-check-circle"></i> Toyota Yaris or Similar</li>
                        </ul>

                        <div class="price">
                            $499
                            <span>Per month + tax</span>
                        </div>

                        <a href="#" class="select-btn">Select</a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-pricing active">
                        <div class="pricing-header">
                            <div class="icon">
                                <i class="fas fa-tachometer-alt"></i>
                            </div>

                            <h3>Standard</h3>
                        </div>

                        <ul class="pricing-features">
                            <li><i class="fas fa-check-circle"></i> insurance included</li>
                            <li><i class="fas fa-check-circle"></i> Toyota Yaris or Similar</li>
                            <li><i class="fas fa-check-circle"></i> insurance included</li>
                            <li><i class="fas fa-check-circle"></i> Toyota Yaris or Similar</li>
                        </ul>

                        <div class="price">
                            $549
                            <span>Per month + tax</span>
                        </div>

                        <a href="#" class="select-btn">Select</a>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                    <div class="single-pricing">
                        <div class="pricing-header">
                            <div class="icon">
                                <i class="fas fa-tachometer-alt"></i>
                            </div>

                            <h3>Midsize</h3>
                        </div>

                        <ul class="pricing-features">
                            <li><i class="fas fa-check-circle"></i> insurance included</li>
                            <li><i class="fas fa-check-circle"></i> Toyota Yaris or Similar</li>
                            <li><i class="fas fa-check-circle"></i> insurance included</li>
                            <li><i class="fas fa-check-circle"></i> Toyota Yaris or Similar</li>
                        </ul>

                        <div class="price">
                            $649
                            <span>Per month + tax</span>
                        </div>

                        <a href="#" class="select-btn">Select</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Pricing'
    }
</script>