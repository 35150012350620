<template>
    <div class="platform-connect ptb-100 pt-0">
        <div class="container">
            <div class="payment-processing-section-title">
                <span>Connect With Us</span>
                <h2>Interact With Your Users On Every Single Platform</h2>
            </div>

            <div class="row">
                <div class="col-lg-3 col-md-3">
                    <a href="#" class="platform-box" target="_blank">
                        <img src="../../assets/images/mailchimp.png" alt="mailchimp">

                        <h3>Mail Chimp</h3>
                    </a>
                </div>

                <div class="col-lg-3 col-md-3">
                    <a href="#" class="platform-box" target="_blank">
                        <img src="../../assets/images/slack.png" alt="slack">

                        <h3>Slack</h3>
                    </a>
                </div>

                <div class="col-lg-3 col-md-3">
                    <a href="#" class="platform-box" target="_blank">
                        <img src="../../assets/images/twitter.png" alt="twitter">

                        <h3>Twitter</h3>
                    </a>
                </div>

                <div class="col-lg-3 col-md-3">
                    <a href="#" class="platform-box" target="_blank">
                        <img src="../../assets/images/instagram.png" alt="instagram">

                        <h3>Instagram</h3>
                    </a>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Platform'
    }
</script>