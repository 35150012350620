<template>
    <div>
        <Preloader v-if="isLoading" />
        <Navbar />
        <MainBanner />
        <Partner />
        <Services />
        <Features />
        <Feedback />
        <Faq />
        <TalkNow />
        <Footer />
    </div>
</template>

<script>
    import Preloader from '../Generation/Preloader'
    import Navbar from '../Generation/Navbar'
    import MainBanner from '../Generation/MainBanner'
    import Partner from '../Generation/Partner'
    import Services from '../Generation/Services'
    import Features from '../Generation/Features'
    import Feedback from '../Generation/Feedback'
    import Faq from '../Generation/Faq'
    import TalkNow from '../Generation/TalkNow'
    import Footer from '../Generation/Footer'

    export default {
        name: 'Generation',
        components: {
            Preloader,
            Navbar,
            MainBanner,
            Partner,
            Services,
            Features,
            Feedback,
            Faq,
            TalkNow,
            Footer,
        },
        data() {
            return {
                isLoading: true
            }
        },
        mounted() {
            setTimeout(() => {
                this.isLoading = false
            }, 2000)
        }
    }
</script>