<template>
    <div>
        <Preloader v-if="isLoading" />
        <SignupArea />
    </div>
</template>

<script>
    import Preloader from '../Signup/Preloader'
    import SignupArea from '../Signup/SignupArea'

    export default {
        name: 'Signup',
        components: {
            Preloader, 
            SignupArea,
        },
        data() {
            return {
                isLoading: true
            }
        },
        mounted() {
            setTimeout(() => {
                this.isLoading = false
            }, 2000)
        }
    }
</script>