<template>
    <div>
        <footer class="footer-area bg-fffcf4">
            <div class="container">
                <div class="row">
                    <div class="col-lg-4 col-md-6">
                        <div class="single-footer-widget">
                            <div class="logo">
                                <a href="#"><img src="../../assets/images/black-logo.png" alt="logo"></a>
                            </div>

                            <ul class="contact-info">
                                <li>1828 Johns Drive Glenview, IL 60025</li>
                                <li><a href="#">(224) 228-8475</a></li>
                                <li><a href="#">support@temva.com</a></li>
                                <li><a href="#">www.temva.com</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-2 col-md-6">
                        <div class="single-footer-widget pl-3">
                            <h3>Quick Links</h3>

                            <ul class="list">
                                <li><a href="#">Home</a></li>
                                <li><a href="#">Features</a></li>
                                <li><a href="#">Screenshots</a></li>
                                <li><a href="#">Pricing</a></li>
                                <li><a href="#">News</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="single-footer-widget pl-5">
                            <h3>Support</h3>

                            <ul class="list">
                                <li><a href="#">Quick Support</a></li>
                                <li><a href="#">Privacy Policy</a></li>
                                <li><a href="#">Copyright</a></li>
                                <li><a href="#">Terms & Conditions</a></li>
                                <li><a href="#">Testimonials</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="single-footer-widget">
                            <h3>My Account</h3>

                            <ul class="list">
                                <li><a href="#">Managed Account</a></li>
                                <li><a href="#">Create Account</a></li>
                                <li><a href="#">Download Software</a></li>
                                <li><a href="#">Support Center</a></li>
                                <li><a href="#">Account Security</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="copyright-area">
                <div class="container">
                    <div class="row align-items-center">
                        <div class="col-lg-7 col-md-7">
                            <p><i class="far fa-copyright"></i> 2019 <a href="#">Temva</a>. All Rights Reserved</p>
                        </div>

                        <div class="col-lg-5 col-md-5">
                            <ul>
                                <li><a href="#"><i class="fab fa-facebook-f"></i></a></li>
                                <li><a href="#"><i class="fab fa-twitter"></i></a></li>
                                <li><a href="#"><i class="fab fa-instagram"></i></a></li>
                                <li><a href="#"><i class="fab fa-linkedin-in"></i></a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>

            <div class="map2"><img src="../../assets/images/map2.png" alt="map"></div>
        </footer>

        <back-to-top bottom="50px" right="50px">
            <div class="go-top">
                <i class="fas fa-angle-up"></i>
            </div>
        </back-to-top>
    </div>
</template>

<script>
    export default {
        name: 'Footer'
    }
</script>