<template>
    <div id="services" class="services-area ptb-100">
        <div class="container">
            <div class="lead-generation-section-title">
                <span>Services</span>
                <h2>We Offer Many Services to Our Great Customers</h2>
                <div class="bar"></div>
                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            </div>

            <div class="services-slides owl-carousel owl-theme">
                <carousel
                    :item = "1"
                    :autplay = "true"
                    :loop = "true"
                    :autoplaySpeed = "true"
                    :autoplayTimeout = "500"
                    :paginationEnabled = "true"
                    :perPageCustom = "[[0, 1], [768, 2], [1200, 3]]"
                >
                    <slide>
                        <div class="services-box">
                            <img src="../../assets/images/blog1.jpg" alt="image">

                            <div class="services-content">
                                <h3>Creative Design</h3>
                                <p>This should be used to tell a story and talk about your product.</p>

                                <ul>
                                    <li>IT Solution</li>
                                    <li>Web Design</li>
                                </ul>
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="services-box">
                            <img src="../../assets/images/blog2.jpg" alt="image">

                            <div class="services-content">
                                <h3>Ultra Responsive</h3>
                                <p>This should be used to tell a story and talk about your product.</p>

                                <ul>
                                    <li class="bg-cdf1d8">IT Solution</li>
                                    <li class="bg-cdf1d8">Web Design</li>
                                </ul>
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="services-box">
                            <img src="../../assets/images/blog3.jpg" alt="image">

                            <div class="services-content">
                                <h3>No Coding Required</h3>
                                <p>This should be used to tell a story and talk about your product.</p>

                                <ul>
                                    <li class="bg-f78acb">IT Solution</li>
                                    <li class="bg-f78acb">Web Design</li>
                                </ul>
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="services-box">
                            <img src="../../assets/images/blog4.jpg" alt="image">

                            <div class="services-content">
                                <h3>Retina Ready</h3>
                                <p>This should be used to tell a story and talk about your product.</p>

                                <ul>
                                    <li class="bg-c679e3">IT Solution</li>
                                    <li class="bg-c679e3">Web Design</li>
                                </ul>
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="services-box">
                            <img src="../../assets/images/blog5.jpg" alt="image">

                            <div class="services-content">
                                <h3>Drag and Drop</h3>
                                <p>This should be used to tell a story and talk about your product.</p>

                                <ul>
                                    <li class="bg-eb6b3d">IT Solution</li>
                                    <li class="bg-eb6b3d">Web Design</li>
                                </ul>
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="services-box">
                            <img src="../../assets/images/blog1.jpg" alt="image">

                            <div class="services-content">
                                <h3>Creative Design</h3>
                                <p>This should be used to tell a story and talk about your product.</p>

                                <ul>
                                    <li>IT Solution</li>
                                    <li>Web Design</li>
                                </ul>
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
import { Carousel, Slide } from 'vue-carousel'

    export default {
        name: 'Services',
        components: {
            Carousel,
            Slide
        }
    }
</script>