<template>
    <div class="cta-area-four ptb-100">
        <div class="container">
            <div class="cta-content-four">
                <h2>Interested In Working With Us Let's TalkNow</h2>
                <p>Whatever the level of support you require, we are sure that we will have a package that meets your needs.</p>
                <a href="#" class="btn btn-primary">Call To Action</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'TalkNow'
    }
</script>