<template>
    <div class="login-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="login-form">
                    <h3>Welcome Back!</h3>

                    <form>
                        <div class="form-group">
                            <label>Email</label>
                            <input type="email" class="form-control" placeholder="Email Address">
                        </div>

                        <div class="form-group">
                            <label>Password</label>
                            <input type="password" class="form-control" placeholder="Password">
                        </div>

                        <button type="submit" class="btn btn-primary">Login</button>

                        <p>
                            <router-link to="/signup" class="pull-left">Create a new account</router-link>
                            
                            <a href="#" class="pull-right">Forgot your password?</a>
                        </p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'LoginArea'
    }
</script>