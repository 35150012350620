<template>
    <nav :class="['navbar navbar-expand-lg navbar-style-one navbar-light bg-light', {'is-sticky': isSticky}]">
        <div class="container">
            <router-link class="navbar-brand" to="/">
                <img src="../../assets/images/white-logo.png" alt="logo">
                <img src="../../assets/images/black-logo.png" alt="logo">
            </router-link>

            <b-navbar-toggle target="navbarSupportedContent">
                <span class="icon-bar top-bar"></span>
                <span class="icon-bar middle-bar"></span>
                <span class="icon-bar bottom-bar"></span>
            </b-navbar-toggle>

            <b-collapse class="collapse navbar-collapse" id="navbarSupportedContent" is-nav>
                <ul class="navbar-nav ms-auto">
                    <li class="nav-item">
                        <a class="nav-link" v-scroll-to="'#home'" href="#">Home</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" v-scroll-to="'#about'" href="#">About</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" v-scroll-to="'#features'" href="#">Features</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" v-scroll-to="'#testimonials'" href="#">Testimonials</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" v-scroll-to="'#pricing'" href="#">Pricing</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" v-scroll-to="'#blog'" href="#">News</a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link" v-scroll-to="'#contact'" href="#">Contact</a>
                    </li>
                </ul>

                <ul class="others-option">
                    <li><router-link to="/login" class="btn btn-primary">Log in</router-link></li>
                </ul>
            </b-collapse>
        </div>
    </nav>
</template>

<script>
    export default {
        name: 'Navbar',
        data(){
            return {
                isSticky: false
            }
        },

        mounted(){
            const that = this
            window.addEventListener('scroll', () => {
                let scrollPos = window.scrollY
                if(scrollPos >= 100){
                    that.isSticky = true
                } else {
                    that.isSticky = false
                }
            })
        }
    }
</script>