<template>
    <div class="error-area ptb-100">
        <div class="container">
            <div class="error-content">
                <img src="../../assets/images/404.png" alt="error">

                <form class="search-form">
                    <input type="text" class="search-field" placeholder="search">

                    <button type="submit" class="search-submit">Search</button>
                </form>

                <div class="error-btn">
                    <router-link to="/" class="btn btn-primary">Back TO Home</router-link>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ErrorArea'
    }
</script>