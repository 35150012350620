<template>
    <div>
        <Preloader v-if="isLoading" />
        <Navbar />
        <PageTitle />
        <Blog />
        <Footer />
    </div>
</template>

<script>
    import Preloader from '../BlogOne/Preloader'
    import Navbar from '../BlogOne/Navbar'
    import PageTitle from '../BlogOne/PageTitle'
    import Blog from '../BlogOne/Blog'
    import Footer from '../BlogOne/Footer'

    export default {
        name: 'BlogOne',
        components: {
            Preloader, 
            Navbar,
            PageTitle,
            Blog,
            Footer,
        },
        data() {
            return {
                isLoading: true
            }
        },
        mounted() {
            setTimeout(() => {
                this.isLoading = false
            }, 2000)
        }
    }
</script>