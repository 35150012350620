<template>
    <section class="subscribe-area ptb-100">
        <div class="container">
            <div class="subscribe-content">
                <h2>Subscribe our Newsletter</h2>
                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                <form class="newsletter-form" data-bs-toggle="validator">
                    <div class="row align-items-center">
                        <div class="col-lg-8 col-md-8">
                            <input type="email" class="form-control" placeholder="Email address" name="EMAIL" required>
                        </div>

                        <div class="col-lg-4 col-md-4">
                            <button type="submit">Subscribe</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>

        <div class="rocket"><img src="../../assets/images/rocket.png" alt="rocket"></div>
        <div class="map"><img src="../../assets/images/map.png" alt="map"></div>
    </section>
</template>

<script>
    export default {
        name: 'Subscribe'
    }
</script>