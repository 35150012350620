<template>
    <div>
        <Preloader v-if="isLoading" />
        <Navbar />
        <PageTitle />
        <Blog />
        <Footer />
    </div>
</template>

<script>
    import Preloader from '../BlogTwo/Preloader'
    import Navbar from '../BlogTwo/Navbar'
    import PageTitle from '../BlogTwo/PageTitle'
    import Blog from '../BlogTwo/Blog'
    import Footer from '../BlogTwo/Footer'

    export default {
        name: 'BlogTwo',
        components: {
            Preloader, 
            Navbar,
            PageTitle,
            Blog,
            Footer,
        },
        data() {
            return {
                isLoading: true
            }
        },
        mounted() {
            setTimeout(() => {
                this.isLoading = false
            }, 2000)
        }
    }
</script>