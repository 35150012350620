<template>
    <div class="download-app ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="download-content">
                        <h3>Download Our Apps <span>Today</span></h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum hendrerit diam lacus, eu euismod augue tincidunt non. Nam vestibulum vulputate sem sed ultrices. Duis et leo quam. Vestibulum eros purus, eleifend vel volutpat id, consectetur in urna. Nam vestibulum vulputate sem sed ultrices.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum hendrerit diam lacus, eu euismod augue tincidunt non. Nam vestibulum vulputate.</p>

                        <div class="download-btn">
                            <a href="#"><i class="fab fa-android"></i> Available On <span>Google Store</span></a>
                            <a href="#"><i class="fab fa-apple"></i> Available On <span>Apple Store</span></a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="download-image">
                        <img src="../../assets/images/man-and-women.png" alt="download">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Download'
    }
</script>