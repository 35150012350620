<template>
    <div class="my-interests ptb-100 pt-0">
        <div class="container">
            <div class="freelancer-section-title">
                <h2>My <span>Interests</span></h2>
                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            </div>

            <div class="row">
                <div class="col-lg-2 col-md-4">
                    <div class="single-interests">
                        <i class="fas fa-gamepad"></i>
                        <h3>Gaming</h3>
                    </div>
                </div>

                <div class="col-lg-2 col-md-4">
                    <div class="single-interests">
                        <i class="fas fa-book"></i>
                        <h3>Reading</h3>
                    </div>
                </div>

                <div class="col-lg-2 col-md-4">
                    <div class="single-interests">
                        <i class="fas fa-music"></i>
                        <h3>Music</h3>
                    </div>
                </div>

                <div class="col-lg-2 col-md-4">
                    <div class="single-interests">
                        <i class="fas fa-film"></i>
                        <h3>Movies</h3>
                    </div>
                </div>

                <div class="col-lg-2 col-md-4">
                    <div class="single-interests">
                        <i class="fas fa-shopping-cart"></i>
                        <h3>Shopping</h3>
                    </div>
                </div>

                <div class="col-lg-2 col-md-4">
                    <div class="single-interests">
                        <i class="fas fa-bicycle"></i>
                        <h3>Bicycling</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Interests'
    }
</script>