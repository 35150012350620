<template>
    <div id="contact" class="contact-area ptb-100 bg-f9f9f9">
        <div class="container">
            <div class="section-title">
                <h2>Contact Us</h2>
                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            </div>

            <div class="row align-items-center">
                <div class="col-lg-8 col-md-12">
                    <form id="contactForm">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Name</label>
                                    <input type="text" class="form-control" required>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Email</label>
                                    <input type="email" class="form-control" required>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Subject</label>
                                    <input type="text" class="form-control" required>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <label>Phone Number</label>
                                    <input type="text" class="form-control" required>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <label>Message</label>
                                    <textarea class="form-control" cols="30" rows="4" required></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">Send Message</button>
                            </div>
                        </div>
                    </form>
                </div>

                <div class="col-lg-4 col-md-12">
                    <div class="contact-info-box">
                        <ul>
                            <li>
                                <i class="fas fa-map-marker-alt"></i>
                                <span>Address:</span>

                                1828 Johns Drive Glenview, IL 60025
                            </li>

                            <li>
                                <i class="fas fa-envelope"></i>
                                <span>Email:</span>
                                
                                <a href="mailto:support@temva.com">support@temva.com</a>
                            </li>

                            <li>
                                <i class="fas fa-phone"></i>
                                <span>Phone:</span>
                                
                                <a href="#">(224) 228-8475</a>
                            </li>

                            <li>
                                <i class="fas fa-globe"></i>
                                <span>Website:</span>
                                
                                <a href="#" target="_blank">www.temva.com</a>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Contact'
    }
</script>