<template>
    <div id="testimonials" class="testimonial-area ptb-100 bg-image">
        <div class="container">
            <div class="app-section-title">
                <h2>Whats Say Our <span>Clients</span></h2>
                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            </div>
            
            <div class="testimonial-slides owl-carousel owl-theme">
                <carousel
                    :autplay = "true"
                    :loop = "true"
                    :paginationEnabled = "true"
                    :autoplaySpeed = "true"
                    :autoplayTimeout = "500"
                    :perPageCustom = "[[0, 1], [768, 1], [992, 2],  [1200, 2]]"
                >
                    <slide>
                        <div class="testimonial-item">
                            <div class="client-image">
                                <img src="../../assets/images/author1.jpg" alt="image">
                            </div>

                            <div class="testimonial-content">
                                <div class="desc">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed malesuada vulputate nisi in fermentum. Vivamus ac libero quis nisi auctor pulvinar. Aenean sit amet lectus posuere, mattis massa eget, ullamcorper diam. Nunc sit amet felis eget arcu congue dictum.</p>
                                </div>

                                <div class="client-info">
                                    <h3>John Smith</h3>
                                    <span>Web Developer</span>
                                </div>
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="testimonial-item">
                            <div class="client-image">
                                <img src="../../assets/images/author2.jpg" alt="image">
                            </div>

                            <div class="testimonial-content">
                                <div class="desc">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed malesuada vulputate nisi in fermentum. Vivamus ac libero quis nisi auctor pulvinar. Aenean sit amet lectus posuere, mattis massa eget, ullamcorper diam. Nunc sit amet felis eget arcu congue dictum.</p>
                                </div>

                                <div class="client-info">
                                    <h3>Steven Smith</h3>
                                    <span>Product Manager</span>
                                </div>
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="testimonial-item">
                            <div class="client-image">
                                <img src="../../assets/images/author3.jpg" alt="image">
                            </div>

                            <div class="testimonial-content">
                                <div class="desc">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed malesuada vulputate nisi in fermentum. Vivamus ac libero quis nisi auctor pulvinar. Aenean sit amet lectus posuere, mattis massa eget, ullamcorper diam. Nunc sit amet felis eget arcu congue dictum.</p>
                                </div>

                                <div class="client-info">
                                    <h3>Alina Eva</h3>
                                    <span>Web Designer</span>
                                </div>
                            </div>
                        </div>
                    </slide>

                    <slide>
                        <div class="testimonial-item">
                            <div class="client-image">
                                <img src="../../assets/images/author4.jpg" alt="image">
                            </div>

                            <div class="testimonial-content">
                                <div class="desc">
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed malesuada vulputate nisi in fermentum. Vivamus ac libero quis nisi auctor pulvinar. Aenean sit amet lectus posuere, mattis massa eget, ullamcorper diam. Nunc sit amet felis eget arcu congue dictum.</p>
                                </div>

                                <div class="client-info">
                                    <h3>Steven Smith</h3>
                                    <span>Product Manager</span>
                                </div>
                            </div>
                        </div>
                    </slide>
                </carousel>
            </div>
        </div>
    </div>
</template>

<script>
    import { Carousel, Slide } from 'vue-carousel'

    export default {
        name: 'Testimonial',
        components: {
            Carousel,
            Slide
        },
    }
</script>