<template>
    <div class="payment-processing-funfacts ptb-100">
        <div class="container">
            <div class="funfacts-inner">
                <div class="single-funfacts-box">
                    <p>Trusted by</p>
                    <h3>99000K</h3>
                    <p>Businesses</p>
                </div>

                <div class="single-funfacts-box">
                    <p>Processing</p>
                    <h3>$50B</h3>
                    <p>Businesses</p>
                </div>

                <div class="single-funfacts-box">
                    <p>Trusted by</p>
                    <h3>654K</h3>
                    <p>Software Platform Integrations</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Funfacts'
    }
</script>