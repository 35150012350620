<template>
    <div class="ux-research-process ptb-100">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="research-process-image">
                        <img src="../../assets/images/ux-women.jpg" alt="image">
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="research-process-content">
                        <h2>Our UX Research Process</h2>
                        <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                        <div class="single-process">
                            <span>1</span>
                            <h3>Objectives</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt dolore magna aliqua.</p>
                        </div>

                        <div class="single-process">
                            <span>2</span>
                            <h3>Hypotheses</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt dolore magna aliqua.</p>
                        </div>

                        <div class="single-process">
                            <span>3</span>
                            <h3>Methods</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt dolore magna aliqua.</p>
                        </div>

                        <div class="single-process">
                            <span>4</span>
                            <h3>Conduct</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt dolore magna aliqua.</p>
                        </div>

                        <div class="single-process">
                            <span>5</span>
                            <h3>Synthesis</h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt dolore magna aliqua.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Research'
    }
</script>