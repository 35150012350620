<template>
    <div id="blog" class="blog-area blog-section ptb-100 bg-fffcf4">
        <div class="container">
            <div class="software-section-title">
                <h2>Latest News</h2>
                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                
                <img src="../../assets/images/line.png" alt="line">
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <img src="../../assets/images/agency-blog1.jpg" alt="image">
                        </div>

                        <div class="blog-post-content">
                            <h3><a href="#">How to Document Processes to Scale Your Agency</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>

                            <a href="#" class="read-more-btn">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-blog-post">
                        <div class="image">
                            <img src="../../assets/images/agency-blog2.jpg" alt="image">
                        </div>

                        <div class="blog-post-content">
                            <h3><a href="#">15 Employee Feedback Tools to Track Your Team</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>

                            <a href="#" class="read-more-btn">Read More</a>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                    <div class="single-blog-post">
                        <div class="image">
                            <img src="../../assets/images/agency-blog3.jpg" alt="image">
                        </div>

                        <div class="blog-post-content">
                            <h3><a href="#">12 Tried-and-True Ways to Improve User</a></h3>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna.</p>

                            <a href="#" class="read-more-btn">Read More</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Blog'
    }
</script>