<template>
    <div class="shop-online-area ptb-100">
        <div class="container">
            <div class="payment-processing-section-title">
                <span>Shopping</span>
                <h2>Shop 50 million online stores and counting.</h2>
                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            </div>

            <div class="row">
                <div class="col-lg-12 col-md-12">
                    <div class="shop-online-image">
                        <img src="../../assets/images/man-and-women.png" alt="image">
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-item">
                        <i class="fas fa-money-check"></i>
                        <h3>Shop your favorite brands.</h3>
                        <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-item">
                        <i class="fas fa-shopping-cart"></i>
                        <h3>Who shops the world? You.</h3>
                        <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                    <div class="single-item">
                        <i class="fas fa-headset"></i>
                        <h3>Buy with confidence.</h3>
                        <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'ShopOnline'
    }
</script>