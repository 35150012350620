<template>
    <div class="funfacts-area-two ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-3 col-md-6">
                    <div class="funfact">
                        <i class="fas fa-users"></i>
                        <h3>1078</h3>
                        <p>Happy Customers</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="funfact">
                        <i class="fas fa-thumbs-up"></i>
                        <h3>510</h3>
                        <p>Complete Projects</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="funfact">
                        <i class="fas fa-bullhorn"></i>
                        <h3>954124</h3>
                        <p>Lines Of Code</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-6">
                    <div class="funfact">
                        <i class="fas fa-crown"></i>
                        <h3>27</h3>
                        <p>Years of Experience</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FunFacts'
    }
</script>