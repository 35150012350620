<template>
    <div id="contact" class="contact-area ptb-100">
        <div class="container">
            <div class="row">
                <div class="col-lg-5 col-md-12">
                    <div class="contact-content">
                        <h3>Leave a <span>Message</span></h3>
                        <h6>This is Photoshop’s version  of Lorem Ipsum. Proin nibh vel velit auctor aliquet aenean.</h6>
                        <p>But I must explain to you how all this mistaken idea of denouncing pleasure and praising pain was born and I will give you a complete account of the system, and expound the actual teachings of the great explorer of the truth, the master-builder of human happiness. No one rejects.</p>
                    </div>
                </div>

                <div class="col-lg-7 col-md-12">
                    <form id="contactForm" class="freelancer-contact-form">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="text" placeholder="Name" class="form-control" required>
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="form-group">
                                    <input type="email" placeholder="Email" class="form-control" required>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <div class="form-group">
                                    <textarea placeholder="Message" class="form-control" cols="30" rows="5" required></textarea>
                                </div>
                            </div>

                            <div class="col-lg-12 col-md-12">
                                <button type="submit" class="btn btn-primary">Send Message</button>
                                <div id="msgSubmit" class="h3 text-center hidden"></div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Contact'
    }
</script>