<template>
    <div>
        <CoolLightBox 
            :items="items" 
            :fullScreen="true"
            :index="index"
            @close="index = null">
        </CoolLightBox>
        
        <div class="cta-area ptb-100 pb-0">
            <div class="container">
                <div class="cta-content">
                    <h2>How App <span>Works</span></h2>
                    <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <div 
                        class="popup-youtube video-btn"
                        v-for="(image, imageIndex) in items"
                        :key="imageIndex"
                        @click="index = imageIndex"
                        style="cursor: pointer"
                    >
                    <i class="fas fa-play"></i>
                    </div>
                </div>

                <div class="cta-inner-content">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-cta-box">
                                <i class="fas fa-download"></i>
                                <h3>Download</h3>
                                <p>Duis cursus dictum enim dictum venenatis. Orci varius natoque penatibus et magnis dis.</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6">
                            <div class="single-cta-box">
                                <i class="fas fa-bars"></i>
                                <h3>Setup It</h3>
                                <p>Duis cursus dictum enim dictum venenatis. Orci varius natoque penatibus et magnis dis.</p>
                            </div>
                        </div>

                        <div class="col-lg-4 col-md-6 offset-lg-0 offset-md-3">
                            <div class="single-cta-box">
                                <i class="far fa-thumbs-up"></i>
                                <h3>Enjoy It</h3>
                                <p>Duis cursus dictum enim dictum venenatis. Orci varius natoque penatibus et magnis dis.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import CoolLightBox from 'vue-cool-lightbox'
    import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

    export default {
        name: 'Works',
        components: { CoolLightBox },
        data: function () {
            return {
                items: [
                    {
                        src: 'https://www.youtube.com/watch?v=bk7McNUjWgw',
                    }
                ],
                index: null
            };
        },
    }
</script>