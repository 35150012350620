<template>
    <div>
        <Preloader v-if="isLoading" />
        <Navbar />
        <PageTitle />
        <SignleBlog />
        <Footer />
    </div>
</template>

<script>
    import Preloader from '../BlogDetails/Preloader'
    import Navbar from '../BlogDetails/Navbar'
    import PageTitle from '../BlogDetails/PageTitle'
    import SignleBlog from '../BlogDetails/SignleBlog'
    import Footer from '../BlogDetails/Footer'

    export default {
        name: 'BlogDetails',
        components: {
            Preloader, 
            Navbar,
            PageTitle,
            SignleBlog,
            Footer,
        },
        data() {
            return {
                isLoading: true
            }
        },
        mounted() {
            setTimeout(() => {
                this.isLoading = false
            }, 2000)
        }
    }
</script>