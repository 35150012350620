<template>
    <div id="home" class="payment-processing-banner">
        <div class="container-fluid">
            <div class="row">
                <div class="col-lg-6 col-md-12 p-0">
                    <div class="payment-processing-banner-content">
                        <h1>The new standard in online payments</h1>
                        <p>It should be noted that although application software is thought of as a program, it can be anything that runs on a computer.</p>

                        <ul>
                            <li>All popular payment methods</li>
                            <li>Payments around the world</li>
                            <li>In-depth data insights</li>
                        </ul>

                        <a href="#" class="btn btn-primary">Get Started!</a>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 p-0">
                    <div class="payment-processing-banner-image">
                        <img src="../../assets/images/payment-banner.jpg" alt="image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'MainBanner'
    }
</script>a