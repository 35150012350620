<template>
    <div id="skill" class="skill-area">
        <div class="container-fluid p-0">
            <div class="row m-0">
                <div class="col-lg-6 col-md-12 p-0">
                    <div class="skill-content ptb-100">
                        <div class="freelancer-section-title">
                            <h2>Some of My <span>Skills</span></h2>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                        </div>

                        <div class="skills">
                            <div class="skill-item">
                                <div class="skill-text">
                                    <span>Branding</span>
                                    <p>95%</p>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" style="width: 95%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>

                            <div class="skill-item">
                                <div class="skill-text">
                                    <span>Marketing</span>
                                    <p>90%</p>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" style="width: 90%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>

                            <div class="skill-item">
                                <div class="skill-text">
                                    <span>Design</span>
                                    <p>95%</p>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" style="width: 95%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>

                            <div class="skill-item">
                                <div class="skill-text">
                                    <span>Development</span>
                                    <p>93%</p>
                                </div>
                                <div class="progress">
                                    <div class="progress-bar" role="progressbar" style="width: 93%;" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12 p-0">
                    <div class="skill-image">
                        <img src="../../assets/images/skill-bg.jpg" alt="skill-image">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Skill'
    }
</script>