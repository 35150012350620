<template>
    <div class="cta-area-two ptb-100">
        <div class="container">
            <div class="cta-content-two">
                <h2>I am Available for Freelancer</h2>
                <a href="#" class="btn btn-primary">Hire Me!</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'HireMe'
    }
</script>