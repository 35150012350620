<template>
    <div>
        <Preloader v-if="isLoading" />
        <Navbar />
        <MainBanner />
        <Partner />
        <HowItsWork />
        <Features />
        <Pricing />
        <General />
        <Feedback />
        <FreeTrial />
        <Blog />
        <Subscribe />
        <Footer />
    </div>
</template>

<script>
    import Preloader from '../Software/Preloader'
    import Navbar from '../Software/Navbar'
    import MainBanner from '../Software/MainBanner'
    import Partner from '../Software/Partner'
    import HowItsWork from '../Software/HowItsWork'
    import Features from '../Software/Features'
    import Pricing from '../Software/Pricing'
    import General from '../Software/General'
    import Feedback from '../Software/Feedback'
    import FreeTrial from '../Software/FreeTrial'
    import Blog from '../Software/Blog'
    import Subscribe from '../Software/Subscribe'
    import Footer from '../Software/Footer'

    export default {
        name: 'Software',
        components: {
            Preloader,
            Navbar,
            MainBanner,
            Partner,
            HowItsWork,
            Features,
            Pricing,
            General,
            Feedback,
            FreeTrial,
            Blog,
            Subscribe,
            Footer,
        },
        data() {
            return {
                isLoading: true
            }
        },
        mounted() {
            setTimeout(() => {
                this.isLoading = false
            }, 2000)
        }
    }
</script>