<template>
    <div>
        <CoolLightBox 
            :items="items" 
            :fullScreen="true"
            :index="index"
            @close="index = null">
        </CoolLightBox>

        <div id="home" class="lead-generation-banner jarallax">
            <div class="d-table">
                <div class="d-table-cell">
                    <div class="container">
                        <div class="row align-items-center">
                            <div class="col-lg-7 col-md-12">
                                <div class="lead-generation-banner-content">
                                    <h1>Great Way To show Your Services</h1>
                                    <p>It should be noted that although application software is thought of as a program, it can be anything that runs on a computer.</p>
                                    
                                    <div class="btn-box">
                                        <a href="#" class="btn btn-primary">Get Started</a>
                                        <div
                                            class="popup-youtube video-btn"
                                            v-for="(image, imageIndex) in items"
                                            :key="imageIndex"
                                            @click="index = imageIndex"
                                            style="cursor: pointer"
                                        >
                                            <i class="fab fa-google-play"></i>
                                            Watch Our Video
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="lead-generation-form">
                                    <div class="form-header">
                                        <h3>Start Your Free Trial</h3>
                                        <span>Supporting call-to-action goes here</span>
                                    </div>

                                    <form>
                                        <div class="form-group">
                                            <input type="text" placeholder="Your name" class="form-control">
                                        </div>

                                        <div class="form-group">
                                            <input type="email" placeholder="Your email" class="form-control">
                                        </div>

                                        <div class="form-group">
                                            <input type="text" placeholder="Phone number" class="form-control">
                                        </div>

                                        <button type="submit" class="btn btn-primary">Call to Action</button>
                                    </form>
                                    <p><i class="fas fa-info-circle"></i> We will never share your personal info</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CoolLightBox from 'vue-cool-lightbox'
import 'vue-cool-lightbox/dist/vue-cool-lightbox.min.css'

    export default {
        name: 'MainBanner',
        components: { CoolLightBox },
        data: function () {
            return {
                items: [
                    {
                        src: 'https://www.youtube.com/watch?v=bk7McNUjWgw',
                    }
                ],
                index: null
            };
        },
    }
</script>