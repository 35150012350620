<template>
    <div>
        <Preloader v-if="isLoading" />
        <Navbar />
        <MainBanner />
        <Services />
        <Works />
        <Features />
        <FeaturedCars />
        <Feedback />
        <FreeTrial />
        <Partner />
        <Pricing />
        <Faq />
        <Download />
        <FooterTop />
        <FooterBottom />
    </div>
</template>

<script>
    import Preloader from '../Subscription/Preloader'
    import Navbar from '../Subscription/Navbar'
    import MainBanner from '../Subscription/MainBanner'
    import Services from '../Subscription/Services'
    import Works from '../Subscription/Works'
    import Features from '../Subscription/Features'
    import FeaturedCars from '../Subscription/FeaturedCars'
    import Feedback from '../Subscription/Feedback'
    import FreeTrial from '../Subscription/FreeTrial'
    import Partner from '../Subscription/Partner'
    import Pricing from '../Subscription/Pricing'
    import Faq from '../Subscription/Faq'
    import Download from '../Subscription/Download'
    import FooterTop from '../Subscription/FooterTop'
    import FooterBottom from '../Subscription/FooterBottom'

    export default {
        name: 'Subscription',
        components: {
            Preloader,
            Navbar,
            MainBanner,
            Services,
            Works,
            Features,
            FeaturedCars,
            Feedback,
            FreeTrial,
            Partner,
            Pricing,
            Faq,
            Download,
            FooterTop,
            FooterBottom,
        },
        data() {
            return {
                isLoading: true
            }
        },
        mounted() {
            setTimeout(() => {
                this.isLoading = false
            }, 2000)
        }
    }
</script>