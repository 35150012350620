<template>
    <section class="funfacts-area-three ptb-100 pt-0">
        <div class="container">
            <div class="saas-section-title">
                <h2>We always try to understand users expectation</h2>
                <div class="bar"></div>
                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            </div>

            <div class="row">
                <div class="col-lg-3 col-md-3 col-6">
                    <div class="funfact-item">
                        <h3>180K</h3>
                        <p>Downloaded</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-6">
                    <div class="funfact-item">
                        <h3>20K</h3>
                        <p>Feedback</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-6">
                    <div class="funfact-item">
                        <h3><span class="odometer" data-count="500">00</span>+</h3>
                        <p>Workers</p>
                    </div>
                </div>

                <div class="col-lg-3 col-md-3 col-6">
                    <div class="funfact-item">
                        <h3>70+</h3>
                        <p>Contrubutors</p>
                    </div>
                </div>
            </div>

            <div class="contact-cta-box">
                <h3>Have any question about us?</h3>
                <p>Don't hesitate to contact us</p>
                <a href="#" class="btn btn-primary">Contact Us</a>
            </div>
        </div>

        <div class="map-bg">
            <img src="../../assets/images/map.png" alt="map">
        </div>
    </section>
</template>

<script>
    export default {
        name: 'FunFacts'
    }
</script>