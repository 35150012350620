<template>
    <div id="services" class="services-area-two ptb-100">
        <div class="container">
            <div class="car-subscription-section-title">
                <span>Services</span>
                <h2>What’s included in a Pluck Subscription?</h2>
                <img src="../../assets/images/car-icon.png" alt="image">
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="services-box">
                        <img src="../../assets/images/car-services1.jpg" alt="image">

                        <div class="services-content">
                            <h3>Free Delivery</h3>
                            <p>This should be used to tell a story and talk about your product.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="services-box">
                        <img src="../../assets/images/car-services2.jpg" alt="image">

                        <div class="services-content">
                            <h3>Switch Cars</h3>
                            <p>This should be used to tell a story and talk about your product.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="services-box">
                        <img src="../../assets/images/car-services3.jpg" alt="image">

                        <div class="services-content">
                            <h3>Choice</h3>
                            <p>This should be used to tell a story and talk about your product.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="services-box">
                        <img src="../../assets/images/car-services4.jpg" alt="image">

                        <div class="services-content">
                            <h3>Insurance</h3>
                            <p>This should be used to tell a story and talk about your product.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="services-box">
                        <img src="../../assets/images/car-services5.jpg" alt="image">

                        <div class="services-content">
                            <h3>Service & Registration</h3>
                            <p>This should be used to tell a story and talk about your product.</p>
                        </div>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="services-box">
                        <img src="../../assets/images/car-services6.jpg" alt="image">

                        <div class="services-content">
                            <h3>No Surprises</h3>
                            <p>This should be used to tell a story and talk about your product.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Services'
    }
</script>