<template>
    <div id="features" class="features-area-two bg-f7fafd ptb-100">
        <div class="container">
            <div class="saas-section-title">
                <h2>Our Amazing Features</h2>
                <div class="bar"></div>
                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            </div>

            <div class="row align-items-center">
                <div class="col-lg-6 col-md-12">
                    <div class="features-content">
                        <div class="row">
                            <div class="col-lg-6 col-md-6">
                                <div class="box">
                                    <i class="far fa-hand-point-up"></i>

                                    Drag and Drop
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="box">
                                    <i class="far fa-gem"></i>

                                    Fully Customizable
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="box">
                                    <i class="fas fa-fighter-jet"></i>

                                    App Integration
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="box">
                                    <i class="fas fa-lock"></i>

                                    Private and Security
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="box">
                                    <i class="fas fa-magic"></i>

                                    High Quality
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="box">
                                    <i class="fas fa-location-arrow"></i>

                                    Easy To Use
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="box">
                                    <i class="fas fa-compress"></i>

                                    Pixel Precision
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="box">
                                    <i class="fas fa-cloud"></i>

                                    Cloud Service
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="box">
                                    <i class="fas fa-edit"></i>

                                    Vector Editing
                                </div>
                            </div>

                            <div class="col-lg-6 col-md-6">
                                <div class="box">
                                    <i class="fas fa-cogs"></i>

                                    Latest Technology
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="col-lg-6 col-md-12">
                    <div class="features-image">
                        <img src="../../assets/images/main-pic.png" alt="main-pic">
                        <img src="../../assets/images/circle-shape.png" class="rotate-image rotateme" alt="circle">
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Features'
    }
</script>