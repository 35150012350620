<template>
    <div>
        <footer class="footer-area-two">
            <div class="container">
                <div class="row align-items-center">
                    <div class="col-lg-3 col-md-6">
                        <div class="contact-info">
                            <h4>Temva</h4>
                            <span>lorem Ipsum donor sit.</span>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="contact-info">
                            <h4>Phone No:</h4>
                            <span><a href="#">(+41) 123 456 7896</a></span>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="contact-info">
                            <h4>Email:</h4>
                            <span><a href="mailto:temva@gmail.com">temva@gmail.com</a></span>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-6">
                        <div class="contact-info">
                            <h4>Address:</h4>
                            <span>123 lorem ipsum New York, USA.</span>
                        </div>
                    </div>

                    <div class="col-lg-12 col-md-12">
                        <div class="bar"></div>
                    </div>

                    <div class="col-lg-7 col-md-7">
                        <p><i class="far fa-copyright"></i> 2022 <a href="#">Temva</a>. All Rights Reserved</p>
                    </div>

                    <div class="col-lg-5 col-md-5">
                        <ul>
                            <li><a href="#" target="_blank"><i class="fab fa-facebook-f"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-twitter"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-instagram"></i></a></li>
                            <li><a href="#" target="_blank"><i class="fab fa-linkedin-in"></i></a></li>
                        </ul>
                    </div>
                </div>
            </div>
        </footer>

        <back-to-top bottom="50px" right="50px">
            <div class="go-top">
                <i class="fas fa-angle-up"></i>
            </div>
        </back-to-top>
    </div>
</template>

<script>
    export default {
        name: 'Footer'
    }
</script>