<template>
    <div class="free-trial-area ptb-100 pt-0">
        <div class="container">
            <div class="free-trial-content">
                <img src="../../assets/images/man-and-women.png" alt="image">
                <h3>Start your 30 days free <br>trials today!</h3>
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                <a href="#" class="btn btn-primary">try it free</a>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'FreeTrial'
    }
</script>