<template>
     <div class="start-now-area ptb-100 pb-0">
        <div class="container">
            <div class="start-now-content">
                <span>Sell Smarter</span>
                <h2>Accept Payments Online. Get Paid Faster.</h2>
                <p>Keep track of all your payments using our beautifully designed payments dashboard. With all your payment information in one place, you'll be a more organized and efficient business owner.</p>
                <a href="#" class="btn btn-primary">Start Now!</a>
            </div>

            <div class="dashboard-image">
                <img src="../../assets/images/banner-chart-home.jpg" alt="image">
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'StartNow'
    }
</script>