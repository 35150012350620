<template>
    <div class="signup-area">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="signup-form">
                    <h3>Create your Account</h3>

                    <form>
                        <div class="form-group">
                            <label>Username</label>
                            <input type="text" class="form-control" placeholder="Username">
                        </div>

                        <div class="form-group">
                            <label>Email</label>
                            <input type="email" class="form-control" placeholder="Email Address">
                        </div>

                        <div class="form-group">
                            <label>Password</label>
                            <input type="password" class="form-control" placeholder="Password">
                        </div>

                        <button type="submit" class="btn btn-primary">Signup</button>

                        <p>Already a registered user? <router-link to="/login">Login!</router-link></p>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'SignupArea'
    }
</script>