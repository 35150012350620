<template>
    <div id="features" class="payment-processing-features ptb-100 bg-f5fbff">
        <div class="container">
            <div class="payment-processing-section-title">
                <span>Services</span>
                <h2>Our Features</h2>
                <p>Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
            </div>

            <div class="row">
                <div class="col-lg-4 col-md-6">
                    <div class="single-features-box">
                        <i class="fas fa-search-dollar"></i>
                        <h3>Advanced Fraud Detection</h3>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-features-box">
                        <i class="fab fa-shirtsinbulk"></i>
                        <h3>Customer Information Manager</h3>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-features-box">
                        <i class="fas fa-money-check"></i>
                        <h3>Payment Types</h3>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-features-box">
                        <i class="fas fa-user-circle"></i>
                        <h3>Account Updater</h3>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-features-box">
                        <i class="fas fa-file-invoice-dollar"></i>
                        <h3>Invoicing</h3>
                    </div>
                </div>

                <div class="col-lg-4 col-md-6">
                    <div class="single-features-box">
                        <i class="fas fa-hand-point-up"></i>
                        <h3>Simple Checkout</h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Features'
    }
</script>a